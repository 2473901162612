import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../Common";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Api, Images } from "../../Constants";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./Sponsor.css";
import ParticipantCountry from "../Register/ParticipantCountry";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateRoute } from "../Reducer/routesSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userReducer } from "../Reducer/userSlice";
import { Link } from "react-router-dom";
import { Loader } from "../../Common/Loader";

const religionOptions = [
  {
    id: "1",
    value: "1",
    label: "Buddhism",
  },
  {
    id: "2",
    value: "2",
    label: "Hinduism",
  },
  {
    id: "3",
    value: "3",
    label: "Islam",
  },
  {
    id: "4",
    value: "4",
    label: "Christainity",
  },
  {
    id: "5",
    value: "5",
    label: "Sikhism",
  },
  {
    id: "6",
    value: "6",
    label: "Others",
  },
  {
    id: "7",
    value: "7",
    label: "Prefer Not to Say",
  },
];

const genderOption = [
  {
    id: "1",
    value: "1",
    label: "male",
  },
  {
    id: "2",
    value: "2",
    label: "female",
  },
  {
    id: "3",
    value: "3",
    label: "other",
  },
];

const Sponsor = (props) => {
  const user = useSelector((state) => state.user);
  const userDetails = user && user.isLoggedIn ? user.details : null;
  // console.log("User Details::", userDetails);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
    watch,
    setError,
  } = useForm();
  const [religion, setReligion] = useState(religionOptions);
  const [gender, setGenderOption] = useState(genderOption);
  const [sponsors, setSponsors] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSponsorshipType, setselectedSponsorshipType] = useState();
  const [paymentType, setPaymentType] = useState();
  const [slug, setSlug] = useState(null);
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [selectedGender, setSelectedGender] = useState(gender[0].value);
  const [exampleIndex, setSelectedIndex] = useState(0);
  const [agree, setAgree] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [sponsorshipType, setSponsorshipType] = useState([]);
  const [loading, setLoading] = useState(false);
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = useParams();
  // useEffect(() => {
  //   console.log(selectedState);
  // }, [selectedState]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state) {
      setSlug(location.state.id);
      getSponsorDetail(location.state.id);
    }
    getSponsorshipType();
  }, [location.state, location.state === null && errors]);

  const handleCountryChange = (index, id) => {
    // Update a participant country with the given index
    setSelectedCountry((prev) => ({ ...prev, [index]: id }));
    console.log(selectedCountry);
    getPaymentMethod(id);
  };
  const handlePaymentChange = (id) => {
    console.log("value", id);
    setValue(`payment_id`, id);
    console.log("value", getValues(`payment_id`));
  };
  // callback function to store the state list
  const handleStateChange = (index, id) => {
    // Update a participant's state with the given index
    setSelectedState((prev) => ({ ...prev, [index]: id }));
    console.log(selectedState);
  };

  // callback function to store the city list
  const handleCityChange = (index, id) => {
    // Update a participant's city with the given index
    setSelectedCity((prev) => ({ ...prev, [index]: id }));
    console.log(selectedCity);
  };
  const onSubmit = (data) => handleFormSubmit(data);

  const handleFormSubmit = async (data) => {
    const formattedData = {
      city_new_name: data.city_new_name?.[0],
      country_id: data.country_id?.[0],
      state_id: data.state_id?.[0],
      street: data.street?.[0],
      zip_code: data.zip_code?.[0],
    };

    data.city_new_name = formattedData.city_new_name;
    data.country_id = formattedData.country_id;
    data.state_id = formattedData.state_id;
    data.street = formattedData.street;
    data.zip_code = formattedData.zip_code;
    // data.phone = Number(data.phone);
    // data.email = data.email.replaceAll('"', "");
    // data.email = data.email.replace(/"/g, "");
    const termsCondition = watch("terms_condition");
    data.terms_condition = termsCondition ? "0" : "1";
    // console.log(`Before: ${data.email}`);
    // data.email = data.email.replace(/"/g, "");
    // console.log(`After: ${data.email}`);
    console.log("formattedData", formattedData);
    console.log("data", data);
    const postData = {
      ...data,
    };
    let res;

    if (!slug) {
      res = await Api.postSponsorDetail(postData);
    } else {
      res = await Api.updateSponsorDetail(postData, slug);
    }
    if (res?.status) {
      console.log("reached here");
      navigate(`/preview-sponsor-page/${res.user_id}`, {
        state: { id: res.user_id },
      });
      console.log("reached here Again");
    } else {
      if (res?.message) {
        const error = res.message;
        console.log("errorrr", error);
        // sets error message for the fields using the setError of react-hook-form
        Object.keys(error).forEach((field) => {
          const errorMessage = error[field];
          // Check if the error message exists and has a length
          if (errorMessage && errorMessage.length > 0) {
            setError(field, {
              type: "manual",
              message: errorMessage, // Assuming errorMessage is a string or an array
            });
          }
        });
      }
    }
  };

  const getSponsorDetail = async (id) => {
    setLoading(true);
    const res = await Api.getSponsorDetailById(id);
    if (res?.status) {
      setSponsors(res.donor_detail);

      setValue(`email`, res.donor_detail.email);
      setValue(`full_name`, res.donor_detail.full_name);
      setValue(`phone`, res.donor_detail.phone);
      setValue(`sex`, res.donor_detail.sex);
      setValue(`religion`, res.donor_detail.religion);
      setValue(`country_id[${exampleIndex}]`, res.donor_detail.country_id);
      setValue(`state_id[${exampleIndex}]`, res.donor_detail.state_id);
      setValue(
        `city_new_name[${exampleIndex}]`,
        res.donor_detail.city_new_name
      );
      setValue(`street[${exampleIndex}]`, res.donor_detail.street);
      setValue(`zip_code[${exampleIndex}]`, res.donor_detail.zip_code);
      setValue(`company_name`, res.donor_detail.company_name);
      setValue(`designation`, res.donor_detail.designation);
      setValue(`sponsorship_type_id`, res.donor_detail.sponsorship_type_id);
      setValue(`payment_id`, res.donor_detail.payment_id);
      setPaymentType(res.donor_detail.payment_id);
      getPaymentMethod(res.donor_detail.country_id);
      setSelectedCountry(() => ({
        [exampleIndex]: res.donor_detail.country_id,
      }));
      setSelectedState(() => ({
        [exampleIndex]: res.donor_detail.state_id,
      }));
      setselectedSponsorshipType(res.donor_detail.sponsorship_type_id);
    }
    setLoading(false);
  };
  const getSponsorshipType = async (e) => {
    const res = await Api.getAllSponsorshipType();
    if (res?.status) {
      console.log(res.data);
      setSponsorshipType(res.data);
    }
  };

  const checkEmail = async (e) => {
    const inputValue = e.target.value;
    const res = await Api.checkEmail(inputValue);

    if (res.status !== false) {
      console.log("res.data", res.data);
      toast.success(
        "Participant with this email already exists! Please login.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      // Dispatch the updateRoute action before navigating
      dispatch(updateRoute(window.location.pathname));

      // navigate to the login route with state
      setTimeout(() => {
        navigate(`/login`, { state: { email: inputValue } });
      }, 2000);
    } else {
      console.log("status", res);
    }
  };
  const getPaymentMethod = async (id) => {
    const res = await Api.getPaymentMethodByCountry(id);

    if (res) {
      console.log("res", res);
      setPaymentMethodData(res.payment_detail);
      if (location.state?.id) {
        setPaymentType(sponsors.payment_id);
      } else {
        setPaymentType(res.payment_detail[0].id);
      }
    } else {
      setPaymentMethodData([]);
      toast.error("No payment modes were found for this country.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleAgreeCheck = () => {
    setChecked(!checked);
    if (checked) {
      setAgree(false);
    } else {
      setAgree(true);
    }
  };

  return (
    <div>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="contactus">
            <div className="container ">
              <div className="row mx-0">
                <div className=" col-md-7 pb-4 ">
                  <h1 className="register-title py-4 fs-2 label-font">
                    Be an event sponsor
                  </h1>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      {/* <div className="col form-group">
                    <label className="required form-label col pl-0">
                      <span>Full Name *</span>
                    </label>
                    {userDetails?.full_name ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          value={user.details.full_name}
                          disabled
                          // {...register("full_name")}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          className={`form-control input-font ${
                            errors.full_name ? "is-invalid" : ""
                          }`}
                          required
                          {...register("full_name")}
                          type="text"
                          placeholder="Full Name"
                          onInvalid={(e) =>
                            e.target.setCustomValidity("Full Name is required.")
                          }
                          onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                        />
                      </>
                    )}
                  </div> */}
                      <div className="form-group ">
                        <label className="form-label col pl-0">
                          <span>Email *</span>
                        </label>
                        {userDetails?.email ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              value={user.details.email}
                              disabled
                              // {...register("email")}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              {...register(`email`, {
                                required: "Valid email is required",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Valid email is required",
                                },
                              })}
                              type="text"
                              onBlur={checkEmail}
                              className={`form-control ${
                                errors?.email && "border-danger"
                              }`}
                              placeholder="Email"
                            />
                          </>
                        )}

                        {errors?.email && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.email.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12 col-md-6 participant-f">
                        <div className="col form-group">
                          <label className="required form-label col pl-0">
                            <span>Full Name *</span>
                          </label>
                          {userDetails?.full_name ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                value={user.details.full_name}
                                disabled
                                // {...register("full_name")}
                              />
                            </>
                          ) : (
                            <>
                              <input
                                className={`form-control input-font ${
                                  errors.full_name ? "is-invalid" : ""
                                }`}
                                required
                                {...register("full_name")}
                                type="text"
                                placeholder="Full Name"
                                onInvalid={(e) =>
                                  e.target.setCustomValidity(
                                    "Full Name is required."
                                  )
                                }
                                onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 participant-l pl-2">
                        <div className="form-group">
                          <label className="required form-label col pl-0">
                            <span>Phone *</span>
                          </label>
                          {userDetails?.phone ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                value={user.details.phone}
                                disabled
                                // {...register("phone")}
                              />
                            </>
                          ) : (
                            <>
                              <input
                                {...register(`phone`, {
                                  required: "Valid phone number is required",
                                  pattern: {
                                    // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                    value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                    message: "Valid phone number is required",
                                  },
                                })}
                                type="tel"
                                className={`form-control ${
                                  errors?.phone && "border-danger"
                                }`}
                                placeholder="Phone Number"
                              />
                            </>
                          )}

                          {errors?.phone && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.phone.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 col-xl-6 mb-3">
                        <label className="required form-label col pl-0">
                          <span>Sex *</span>
                        </label>
                        {userDetails?.sex ? (
                          <>
                            <input
                              className="form-control"
                              value={user.details.sex
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                              disabled
                              type="text"
                              // {...register("sex")}
                            />
                          </>
                        ) : (
                          <>
                            <select
                              {...register(`sex`, {
                                required: "Sex is required.",
                              })}
                              className={`form-control ${
                                errors?.sex && "border-danger"
                              }`}
                              defaultValue={selectedGender}
                              onChange={(e) =>
                                setSelectedGender(e.target.value)
                              }
                            >
                              {/* <option value="">Choose option</option> */}
                              {gender.map((elem) => (
                                <option
                                  key={elem.value}
                                  value={elem.label}
                                  className="text-capitalize"
                                >
                                  {elem.label
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </option>
                              ))}
                            </select>
                          </>
                        )}
                        {errors?.sex && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.sex.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>
                      <div className="col-12 col-md-6 pl-2 mb-3">
                        <label className="form-label col pl-0">
                          <span>Religion*</span>
                        </label>
                        {userDetails?.religion ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              value={user.details.religion
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                              disabled
                              // {...register("religion")}
                            />
                          </>
                        ) : (
                          <>
                            <select
                              {...register(`religion`, {
                                required: "Religion is required.",
                              })}
                              className={`form-control ${
                                errors?.religion && "border-danger"
                              }`}
                              // value={selectedReligion}
                              onChange={(e) => setReligion(e.target.value)}
                            >
                              <option value="" hidden>
                                Choose option
                              </option>
                              {religionOptions.map((elem) => (
                                <option key={elem.value} value={elem.label}>
                                  {elem.label}
                                </option>
                              ))}
                            </select>
                          </>
                        )}

                        {errors?.religion && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.religion.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 participant-f mb-3">
                      <div className="form-group">
                        <label className="form-label col pl-0">
                          <span>Address</span>
                        </label>
                        {!location.state?.id ? (
                          <>
                            <ParticipantCountry
                              key={exampleIndex}
                              index={exampleIndex}
                              onCountryChange={handleCountryChange}
                              onStateChange={handleStateChange}
                              onCityChange={handleCityChange}
                              register={register}
                              errors={errors}
                              setValue={setValue}
                              selectedCountryParent={
                                selectedCountry[exampleIndex]
                              }
                              selectedStateParent={selectedState[exampleIndex]}
                              selectedCityParent={selectedCity[exampleIndex]}
                            />
                          </>
                        ) : (
                          <>
                            {selectedCountry && (
                              <>
                                <ParticipantCountry
                                  key={exampleIndex}
                                  index={exampleIndex}
                                  onCountryChange={handleCountryChange}
                                  onStateChange={handleStateChange}
                                  onCityChange={handleCityChange}
                                  register={register}
                                  errors={errors}
                                  setValue={setValue}
                                  selectedCountryParent={
                                    selectedCountry[exampleIndex]
                                  }
                                  selectedStateParent={
                                    selectedState
                                      ? selectedState[exampleIndex]
                                      : undefined
                                  }
                                  selectedCityParent={
                                    selectedCity[exampleIndex]
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12 col-md-6 participant-f">
                        <div className="form-group">
                          <label className="required form-label col pl-0">
                            <span>Company Name *</span>
                          </label>
                          <input
                            {...register(`company_name`, {
                              required: "Company Name is required",
                            })}
                            type="text"
                            className={`form-control ${
                              errors?.company_name && "border-danger"
                            }`}
                            placeholder="Company Name"
                          />
                          {errors?.company_name && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.company_name.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 participant-l pl-2">
                        <div className="form-group ">
                          <label className="form-label col pl-0">
                            <span>Designation *</span>
                          </label>
                          <input
                            {...register(`designation`, {
                              required: "Designation is required",
                            })}
                            type="text"
                            className={`form-control ${
                              errors?.designation && "border-danger"
                            }`}
                            placeholder="Designation"
                          />
                          {errors?.email && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.designation.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6 participant-l pl-2 mb-4">
                        <label className="form-label col pl-0">
                          <span>Sponsorship type *</span>
                        </label>
                        <select
                          {...register(`sponsorship_type_id`, {
                            required: "Sponsorship type is required.",
                          })}
                          className={`form-control ${
                            errors?.sponsorship_type_id && "border-danger"
                          }`}
                          value={selectedSponsorshipType}
                          onChange={(e) =>
                            setselectedSponsorshipType(e.target.value)
                          }
                        >
                          <option value="" hidden>
                            Choose option
                          </option>
                          {sponsorshipType.map((elem) => (
                            <option key={elem.id} value={elem.id}>
                              {elem.name} {elem.currency} {elem.amount}
                            </option>
                          ))}
                        </select>
                        {errors?.sponsorship_type_id && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.sponsorship_type_id.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>
                      <div className="col-12 col-md-6 participant-l pe-xl-3">
                        <div className="form-group ">
                          <label className="form-label col pl-0">
                            <span>Payment Method *</span>
                          </label>
                          <div
                            className="form-radio-group d-flex flex-row"
                            id="form-radio-group"
                          >
                            {paymentMethodData?.map((elem, index) => (
                              <label
                                key={elem.id}
                                className="form-radio-item payment-div"
                                data-toggle="modal"
                                data-target="#paymentGatewayModal"
                                title={elem.payment_method}
                              >
                                <input
                                  type="radio"
                                  value={elem.id}
                                  id={elem.id}
                                  name="payment_id"
                                  checked={paymentType === elem.id}
                                  {...register("payment_id", {
                                    required: "Donation Detail is required.",
                                  })}
                                  onChange={() => {
                                    setPaymentType(elem.id);
                                    handlePaymentChange(elem.id);
                                  }}
                                />
                                <img
                                  className="payment_logo img-fluid"
                                  src={elem.icon_url}
                                  alt={elem.payment_method}
                                />
                              </label>
                            ))}
                          </div>
                          {errors?.payment_id && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.payment_id.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <input
                        type="checkbox"
                        className="me-2"
                        onClick={handleAgreeCheck}
                        {...register(`terms_condition`, { required: true })}
                      />
                      <span>
                        I agree to{" "}
                        <Link to="/terms-and-condition" target="_blank">
                          Terms and Condition
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        className="contact-btn send-btn px-4"
                        type="submit"
                        disabled={!agree}
                      >
                        Send
                      </Button>
                    </div>
                    {/* <div className=" d-flex justify-content-end">
                  <Button className="contact-btn send-btn px-4" type="submit">
                    Send
                  </Button>
                </div> */}
                  </form>
                </div>
                <div className=" col-md-5 py-4 px-0 ps-lg-4">
                  <img
                    src={Images.dharmalogo1}
                    alt="dharma"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </div>
  );
};

export default Sponsor;
