import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../Common";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Api, Images } from "../../Constants";
import ReCAPTCHA from "react-google-recaptcha";
import "./Profile.css";
import Avatar from "react-avatar";
import ParticipantCountry from "../Register/ParticipantCountry";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { CiSquareCheck } from "react-icons/ci";
import { FaCircleCheck } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import SponsorPreviewPage from "../SponsorPreviewPage/SponsorPreviewPage";
import MembershipPreviewPage from "../MembershipPreviewPage/MembershipPreviewPage";
import DonationPreviewPage from "../Donate/DonationPreviewPage";
import RegisterPreviewPage from "../RegisterPreviewPage/RegisterPreviewPage";
import { setCurrent } from "../Reducer/userSlice";
import { Loader } from "../../Common/Loader";
const religionOptions = [
  {
    id: "1",
    value: "1",
    label: "Buddhism",
  },
  {
    id: "2",
    value: "2",
    label: "Hinduism",
  },
  {
    id: "3",
    value: "3",
    label: "Islam",
  },
  {
    id: "4",
    value: "4",
    label: "Christainity",
  },
  {
    id: "5",
    value: "5",
    label: "Sikhism",
  },
  {
    id: "6",
    value: "6",
    label: "Others",
  },
  {
    id: "7",
    value: "7",
    label: "Prefer Not to Say",
  },
];

const genderOption = [
  {
    id: "1",
    value: "1",
    label: "male",
  },
  {
    id: "2",
    value: "2",
    label: "female",
  },
  {
    id: "3",
    value: "3",
    label: "other",
  },
];
const Profile = (props) => {
  const [sponsor, setSponsor] = useState(null);
  const [member, setMember] = useState(null);
  const [donation, setDonation] = useState(null);
  const [registered, setRegistered] = useState(null);
  const [sponsor2, setSponsor2] = useState(null);
  const [member2, setMember2] = useState(null);
  const [donation2, setDonation2] = useState(null);
  const [registered2, setRegistered2] = useState(null);

  const [religion, setReligion] = useState(religionOptions);
  const [gender, setGenderOption] = useState(genderOption);

  const [exampleIndex, setSelectedIndex] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedGender, setSelectedGender] = useState(gender[0].value);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   console.log(sponsor);
  // }, [sponsor]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (location.state) {
    // setSlug(location.state.id);
    // getSponsorDetail(location.state.id);
    getDonor(user.details.id);
    getSponsor(user.details.id);
    getMember(user.details.id);
    getRegister(user.details.id);
    // }
  }, [location.state, location.state === null && errors]);
  // console.log("use", user);
  const userDetails =
    user && user.isLoggedIn ? user.details : navigate("/login");

  const onSubmit = async (data) => {
    console.log(data);
  };
  // const handlePaymentChange = (id) => {
  //   console.log("value", id);
  //   setValue(`payment_id`, id);
  //   console.log("value", getValues(`payment_id`));
  // };
  // callback function to store the state list
  const handleStateChange = (index, id) => {
    // Update a participant's state with the given index
    setSelectedState((prev) => ({ ...prev, [index]: id }));
    console.log(selectedState);
  };
  const handleCountryChange = (index, id) => {
    // Update a participant country with the given index
    setSelectedCountry((prev) => ({ ...prev, [index]: id }));
    console.log(selectedCountry);
    // getPaymentMethod(id);
  };
  // callback function to store the city list
  const handleCityChange = (index, id) => {
    // Update a participant's city with the given index
    setSelectedCity((prev) => ({ ...prev, [index]: id }));
    console.log(selectedCity);
  };

  const dispatch = useDispatch();
  const getMember = async (id) => {
    const res = await Api.getDataFromMembershipId(id);
    // console.log(res);
    setMember(res.membership_detail);
    // setMember2(id);
  };
  const getSponsor = async (id) => {
    const res = await Api.getSponsorDetailById(id);
    // console.log(res);
    setSponsor(res.donor_detail);
    // setSponsor2(id);
  };
  const getDonor = async (id) => {
    setLoading(true);
    const res = await Api.getDataFromDonationId(id);
    // console.log(res);
    setDonation(res.donor_detail);
    // setDonation2(id);
    setLoading(false);
  };
  const getRegister = async (id) => {
    const res = await Api.getParticiapantDataFromRegistrationId(id);
    // console.log(res);
    setRegistered(res.participant_detail);
    // setRegistered2(id);
  };
  const handleSponsor = async (id) => {
    setSponsor2(id);
    setDonation2(null);
    setMember2(null);
    setRegistered2(null);
  };
  const handleMember = async (id) => {
    setMember2(id);
    setSponsor2(null);
    setDonation2(null);
    setRegistered2(null);
  };
  const handleDonation = async (id) => {
    setDonation2(id);
    setSponsor2(null);
    setMember2(null);
    setRegistered2(null);
  };
  const handleRegistration = async (id) => {
    setRegistered2(id);
    setSponsor2(null);
    setMember2(null);
    setDonation2(null);
  };
  return (
    <div>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {userDetails && (
            <div className="profile">
              <div className="container ">
                <div className="row mx-0">
                  <div className="col-md-5">
                    <h1 className="profile-title py-4 fs-2 label-font">
                      Profile
                    </h1>
                    <div className="profile-card mb-5">
                      <div className="d-flex justify-content-center">
                        <div className="profile-image">
                          <img src={Images.user} />
                        </div>
                      </div>
                      <div className="label-font text-center mt-3">
                        <b>{user.details.full_name}</b>
                      </div>
                      <dl class="row mt-3">
                        <>
                          <dt class="col-9">
                            <div>Donation</div>
                          </dt>
                          {donation ? (
                            <dd class="col-3">
                              <FaEye
                                onClick={() => handleDonation(user.details.id)}
                              />
                            </dd>
                          ) : (
                            <dd class="col-3">
                              {/* <FaEye
                            onClick={() => handleDonation(user.donor.id)}
                          /> */}
                              <Link to="/donate-now">Donate</Link>
                            </dd>
                          )}
                        </>

                        <>
                          <dt class="col-9">
                            <div>Registered Member</div>
                          </dt>
                          {registered ? (
                            <dd class="col-3">
                              <FaEye
                                onClick={() =>
                                  handleRegistration(user.details.id)
                                }
                              />
                            </dd>
                          ) : (
                            <dd class="col-3">
                              <Link to="/register">Register</Link>
                            </dd>
                          )}
                        </>

                        <>
                          <dt class="col-9">
                            <div>Dharma Ideal Sponsor Member</div>
                          </dt>
                          {member ? (
                            <dd class="col-3">
                              <FaEye
                                onClick={() => handleMember(user.details.id)}
                              />
                            </dd>
                          ) : (
                            <dd class="col-3">
                              <Link to="/member">Register</Link>
                            </dd>
                          )}
                        </>

                        <>
                          <dt class="col-9">
                            <div>International Event Sponsor</div>
                          </dt>
                          {sponsor ? (
                            <dd class="col-3">
                              <FaEye
                                onClick={() => handleSponsor(user.details.id)}
                              />
                            </dd>
                          ) : (
                            <dd class="col-3">
                              <Link to="/sponsor">Register</Link>
                            </dd>
                          )}
                        </>
                      </dl>
                    </div>
                  </div>
                  <div className="col-md-7 pb-4">
                    {!donation2 && !member2 && !sponsor2 && !registered2 && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="profile-title py-4 fs-2 label-font">
                          Personal Details
                        </h1>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">
                              Full Name *
                            </label>
                            <input
                              className={`form-control input-font ${
                                errors.full_name ? "is-invalid" : ""
                              }`}
                              required
                              {...register("full_name")}
                              type="text"
                              placeholder="Full Name"
                              defaultValue={user.details.full_name}
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Full Name is required."
                                )
                              }
                              disabled
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {errors?.full_name && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.full_name.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">
                              Phone Number *
                            </label>
                            <input
                              {...register(`phone`, {
                                required: "Valid phone number is required",
                                pattern: {
                                  // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                  value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                  message: "Valid phone number is required",
                                },
                              })}
                              type="tel"
                              disabled
                              defaultValue={user.details.phone}
                              className={`form-control ${
                                errors?.phone && "border-danger"
                              }`}
                              placeholder="Phone Number"
                            />
                            {errors?.phone && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.phone.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </div>

                          <div className="col form-group">
                            <label className="label-font ps-1">Email *</label>
                            <input
                              {...register(`email`, {
                                required: "Valid email is required",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Valid email is required",
                                },
                              })}
                              type="text"
                              className={`form-control ${
                                errors?.email && "border-danger"
                              }`}
                              placeholder="Email"
                              disabled
                              defaultValue={user.details.email}
                            />
                            {/* {errors.email && (
                        <div className="invalid-feedback">
                          Email is required.
                        </div>
                      )} */}
                            {/* <div
                      dangerouslySetInnerHTML={{
                        __html: errors?.email.message,
                      }}
                      className="text-danger"
                    /> */}
                            {errors?.email && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.email.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6 col-xl-6 mb-3">
                            <label className="required form-label col pl-0">
                              <span>Sex *</span>
                            </label>
                            <select
                              {...register(`sex`, {
                                required: "Sex is required.",
                              })}
                              className={`form-control ${
                                errors?.sex && "border-danger"
                              }`}
                              // defaultValue={selectedGender}
                              defaultValue={user.details.sex}
                              disabled
                              onChange={(e) =>
                                setSelectedGender(e.target.value)
                              }
                            >
                              {/* <option value="">Choose option</option> */}
                              {gender.map((elem) => (
                                <option
                                  key={elem.value}
                                  value={elem.label}
                                  className="text-capitalize"
                                >
                                  {elem.label
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </option>
                              ))}
                            </select>
                            {errors?.sex && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.sex.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </div>
                          <div className="col-12 col-md-6 pl-2 mb-3">
                            <label className="form-label col pl-0">
                              <span>Religion*</span>
                            </label>
                            <select
                              {...register(`religion`, {
                                required: "Religion is required.",
                              })}
                              className={`form-control ${
                                errors?.religion && "border-danger"
                              }`}
                              // value={selectedReligion}
                              disabled
                              defaultValue={user.details.religion}
                              onChange={(e) => setReligion(e.target.value)}
                            >
                              {/* <option value="" hidden>
                        Choose option
                      </option> */}
                              {religionOptions.map((elem) => (
                                <option key={elem.value} value={elem.label}>
                                  {elem.label}
                                </option>
                              ))}
                            </select>
                            {errors?.religion && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.religion.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12 participant-f mb-3">
                            <div className="form-group">
                              <label className="form-label col pl-0">
                                <span>Address</span>
                              </label>
                              {!location.state?.id ? (
                                <>
                                  <ParticipantCountry
                                    key={exampleIndex}
                                    index={exampleIndex}
                                    onCountryChange={handleCountryChange}
                                    onStateChange={handleStateChange}
                                    onCityChange={handleCityChange}
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    selectedCountryParent={
                                      selectedCountry[exampleIndex]
                                    }
                                    selectedStateParent={
                                      selectedState[exampleIndex]
                                    }
                                    selectedCityParent={
                                      selectedCity[exampleIndex]
                                    }
                                    // profile={true}
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedCountry && (
                                    <>
                                      <ParticipantCountry
                                        key={exampleIndex}
                                        index={exampleIndex}
                                        onCountryChange={handleCountryChange}
                                        onStateChange={handleStateChange}
                                        onCityChange={handleCityChange}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                        selectedCountryParent={
                                          selectedCountry[exampleIndex]
                                        }
                                        selectedStateParent={
                                          selectedState
                                            ? selectedState[exampleIndex]
                                            : undefined
                                        }
                                        selectedCityParent={
                                          selectedCity[exampleIndex]
                                        }
                                        profile={false}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div className=" d-flex justify-content-end">
                    <Button className="contact-btn send-btn px-4" type="submit">
                      Send
                    </Button>
                  </div> */}
                      </form>
                    )}

                    {donation2 && <DonationPreviewPage data={donation} />}
                    {member2 && <MembershipPreviewPage data={member} />}
                    {sponsor2 && <SponsorPreviewPage data={sponsor} />}
                    {registered2 && <RegisterPreviewPage data={registered} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </div>
  );
};

export default Profile;
