import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../Common";
import {} from "react-router-dom";
import { Api, Images } from "../../Constants";
import { Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setCurrent } from "../Reducer/userSlice";
import { Loader } from "../../Common/Loader";

const DonationPreviewPage = (props) => {
  const location = useLocation();

  const [slug, setSlug] = useState(null);
  const [participantsData, setParticipantData] = useState();
  const [loading, setLoading] = useState(true);
  const pathName = useParams();
  const navigate = useNavigate();
  const data = props.data;
  useEffect(() => {
    const id = location.state && location.state.id;
    window.scrollTo(0, 0);
    setSlug(id);
    getPreviewData(id);
    data && setParticipantData(data);
  }, [location.state]);

  const getPreviewData = async (id) => {
    setLoading(true);
    const res = await Api.getDataFromDonationId(id);
    if (res.status) {
      console.log(res);
      setParticipantData(res.donor_detail);
      // setParticipantData(stateOptions)
    } else {
      // setParticipants({})
    }
    setLoading(false);
  };

  // const sendEmail = async (id) => {
  //     const res = await Api.sendEmailToDonorMember(id);
  //     if (res) {
  //         const activateRes = await Api.sendEmailtoActivateMember(id);
  //         if (activateRes) {
  //             paymentGateway(id)
  //             // navigate(`/donation-confirmation/`, { state: { id: id } });

  //         }
  //     } else {
  //         toast.error("Something went wrong. Please try again!", {
  //             position: toast.POSITION.TOP_RIGHT,
  //         });
  //     }

  // }

  const sendEmail = async (id) => {
    const res = await Api.sendEmailToDonorMember(id);
    if (res) {
      // if (location.state?.isUserLoggedIn === true) {
      //     paymentGateway(id)
      // } else {
      //     const activateRes = await Api.sendEmailtoActivateMember(id);
      //     if (activateRes) {
      //         paymentGateway(id)
      //         // navigate(`/donation-confirmation/`, { state: { id: id } });

      //     }
      // }
      // dispatch(
      //   setCurrent({
      //     membership: parsedMembership,
      //     donor: parsedDonor,
      //     user_detail: parsedUser_detail,
      //     event_registration: parsedEvent_registration,
      //     sponsorship: parsedSponsorship,
      //   })
      // );
      const activateRes = await Api.sendEmailtoActivateMember(id);
      if (activateRes) {
        // paymentGateway(id)

        navigate(`/donation-confirmation/`, { state: { id: id } });
      }
    } else {
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const paymentGateway = async (id) => {
    const res = await Api.paymentGateway(id);
    if (res) {
      console.log("paymentGateway res", res.url);
      if (res.url) {
        toast.success("Redirecting to Payment Gateway...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = res.url;
        }, 1000);
      }
    }
  };

  return (
    <>
      {!data && <Header links={props.links} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-lg">
            <div className="">
              <div className="row form-group mx-0 mb-4">
                <h1 className="register-title py-4 fs-2 label-font">
                  {data
                    ? "Donation Details"
                    : "Please Confirm Your Donation Details"}
                </h1>

                <div className={`${data ? "col-12" : "col-lg-8"}`}>
                  <div className="row">
                    <div
                      className={`col-12 ${
                        !data && "col-md-6"
                      } my-1 my-lg-2 form-group`}
                    >
                      <label className="form-label col pl-0">
                        <span>Email</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.email}
                      </div>
                    </div>
                    <div
                      className={`col-12 ${
                        !data && "col-md-6"
                      } my-1 my-lg-2 form-group`}
                    >
                      <label className="form-label col pl-0">
                        <span>Full Name</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.full_name}
                      </div>
                    </div>

                    <div
                      className={`${
                        data ? "col-6" : "col-5 col-md-4"
                      } participant-f my-1 my-lg-2 form-group`}
                    >
                      <label className="required form-label col pl-0">
                        <span>Sex *</span>
                      </label>
                      <div className="preview-data  text-capitalize">
                        {participantsData?.sex}
                      </div>
                    </div>
                    <div
                      className={`${
                        data ? "col-6" : "col-7 col-md-8"
                      } participant-l my-1 my-lg-2 form-group`}
                    >
                      <label className="form-label col pl-0">
                        <span>Religion*</span>
                      </label>
                      <div className="preview-data  text-capitalize">
                        {participantsData?.religion}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                      <label className="required form-label col pl-0">
                        <span>Phone *</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.phone}
                      </div>
                    </div>

                    <div className="col-12 participant-f my-1 my-lg-2">
                      <label className="form-label col pl-0">
                        <span>Address</span>
                      </label>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 px-0 pe-md-3">
                          <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                            Country
                          </label>
                          <div className="preview-data text-capitalize">
                            {participantsData?.country_name}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 px-0 mt-3 my-md-0">
                          <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                            State
                          </label>
                          <div className="preview-data text-capitalize">
                            {participantsData?.state_name
                              ? participantsData?.state_name
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 mt-3">
                          <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                            City
                          </label>
                          <div className="preview-data text-capitalize">
                            {participantsData?.city_new_name
                              ? participantsData?.city_new_name
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 mt-3">
                          <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                            Street
                          </label>
                          <div className="preview-data text-capitalize">
                            {participantsData?.street
                              ? participantsData?.street
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 ps-xl-3 pe-xl-0 mt-3">
                          <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                            Zip Code
                          </label>
                          <div className="preview-data">
                            {participantsData?.zip_code
                              ? participantsData?.zip_code
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 my-1 my-lg-2 form-group">
                      <label className="form-label col pl-0">
                        <span>Donation Details</span>
                      </label>
                      <div className="preview-data">
                        <ul className="mb-0">
                          {participantsData?.donation.map((elem, index) => (
                            <li key={index}>{elem.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                      <label className="form-label col pl-0">
                        <span>Amount</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.currency}{" "}
                        {participantsData?.amount
                          ? participantsData?.amount
                          : "-"}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6 my-1 my-lg-2 participant-l">
                                <div className="form-group ">
                                    <label className="form-label col pl-0"><span>Payment Mode</span></label>
                                    <div className="col-4 col-md-6 col-xxl-12">
                                        <img className="payment_logo img-fluid" src={participantsData?.payment_icon_url} alt="payment_mode" />
                                    </div>
                                </div>
                            </div> */}
                  {!data && (
                    <>
                      <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                        <Button
                          as={Link}
                          to="/edit-donation/"
                          state={{ id: slug }}
                          className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1"
                          type="button"
                        >
                          Edit
                        </Button>

                        <Button
                          className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1"
                          type="button"
                          onClick={() => sendEmail(slug)}
                        >
                          Confirm
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                {!data && (
                  <>
                    <div className=" col-lg-4 d-none d-lg-block">
                      <img
                        src={Images.dharmalogo1}
                        alt="dharma"
                        className="img-fluid w-100"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!data && (
        <>
          <Footer
            links={props.links}
            contacts={props.contacts}
            footerDescription={props.footerDescription}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default DonationPreviewPage;
