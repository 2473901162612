import React, { useEffect, useState } from "react";

import { Link, useParams, useLocation } from "react-router-dom";
import { Footer, Header } from "../../Common";
import "../RegisterConfirmationPage/RegisterConfirmationPage.css";
import { Button } from "react-bootstrap";

const MembershipConfirmationPage = (props) => {
  const [slug, setSlug] = useState(null);
  const pathName = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    setSlug(pathName.id);
  }, [pathName.id]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     const id = location.state && location.state.id;

  //     setSlug(id)
  //     // getPreviewData(id);
  // }, [location.state]);

  return (
    <>
      <Header links={props.links} />
      <div className="container text-center">
        <div className="text-congrats mt-5 pt-md-5 mx-md-4">
          Congratulations ! You have Successfully Submitted Your Application for{" "}
          <br />
          'Dharma Ideal Sponsor Member' <br />
          {/* and Your Membership Number is
                    <span className="text-reg"> #{slug}</span> */}
          <div className="text-email mb-md-5 pb-md-5 pb-lg-0 mb-lg-2 mt-2 ">
            For further details please contact us at{" "}
            <a
              href={`mailto:${props.contacts.email}`}
              title={props.contacts.email}
              target="_blank"
            >
              {props.contacts.email}
            </a>
          </div>
          <div className="mb-5 pb-md-5 mt-5 row justify-content-center">
            <Link to="/">
              <button className="btn  btn-lg  btn-back">Back to home</button>
            </Link>

            {/* <Button as={Link}
                            to='/travel-assistance/'
                            state={{ id: slug }}
                            className="btn  btn-lg  btn-back col-5 col-lg-2 my-2 py-2 mx-1" type="button" style={{ height: '67px' }}>
                            Need assistance in Travel?
                        </Button>

                        <Button as={Link}
                            to='/donate-now/'
                            state={{ id: slug }}
                            className="btn  btn-lg  btn-back col-5 col-lg-2 my-2 py-2 mx-1 d-grid align-items-center" type="button" style={{ height: '67px' }}>
                            Donate
                        </Button> */}
          </div>
        </div>
      </div>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};

export default MembershipConfirmationPage;
