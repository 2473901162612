import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../Common";
import { Button } from "react-bootstrap";
import { Api, Images } from "../../Constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import RedirectPage from "./RedirectPage";
import { setCurrent } from "../Reducer/userSlice";
import { useDispatch } from "react-redux";
import { Loader } from "../../Common/Loader";
const SponsorPreviewPage = (props) => {
  const data = props.data;
  const location = useLocation();
  const [slug, setSlug] = useState(null);
  const [participantsData, setParticipantData] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const pathName = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const id = location.state && location.state.id;
    window.scrollTo(0, 0);
    setSlug(id);
    getPreviewData(id);
    data && setParticipantData(data);
  }, [location.state]);
  const getPreviewData = async (id) => {
    setLoading2(true);
    const res = await Api.getSponsorDetailById(id);
    if (res.status) {
      console.log(res);
      setParticipantData(res.donor_detail);
      //   console.log("res", res.membership_detail?.family_member);
      // setParticipantData(stateOptions)
    } else {
      // setParticipants({})
    }
    setLoading2(false);
  };
  // const sendEmail = async (id) => {
  //   const res = await Api.sendEmailToSponsorMember(id);
  //   if (res) {
  //     const activateRes = await Api.sendEmailToSponsorMember(id);
  //     if (activateRes) {
  //       navigate(`/sponsor-confirmation/`, { state: { id: id } });
  //     }
  //   } else {
  //     toast.error("Something went wrong. Please try again!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };
  // const dispatch = useDispatch();
  // const membership = localStorage.getItem("membership");
  // const donor = localStorage.getItem("donor");
  // const user_detail = localStorage.getItem("user_detail");
  // const event_registration = localStorage.getItem("event_registration");
  // const sponsorship = localStorage.getItem("sponsorship");

  // const parsedMembership = JSON.parse(membership);
  // const parsedDonor = JSON.parse(donor);
  // const parsedUser_detail = JSON.parse(user_detail);
  // const parsedEvent_registration = JSON.parse(event_registration);
  // const parsedSponsorship = JSON.parse(sponsorship);
  const sendEmail = async (id) => {
    setLoading(true);
    const res = await Api.sendEmailToSponsorMember(id);
    if (res) {
      console.log("res", res);

      if (location.state?.isUserLoggedIn === true) {
        // dispatch(
        //   setCurrent({
        //     membership: parsedMembership,
        //     donor: parsedDonor,
        //     user_detail: parsedUser_detail,
        //     event_registration: parsedEvent_registration,
        //     sponsorship: parsedSponsorship,
        //   })
        // );
        paymentGateway(id);
      } else {
        const activateRes = await Api.sendEmailtoActivateMember(id);
        if (activateRes) {
          paymentGateway(id);
          // navigate(`/membership-confirmation/`, { state: { id: id } });
          // navigate(`/membership-confirmation/${id}`);
        }
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const paymentGateway = async (id) => {
    const res = await Api.sponsorshipPaymentGateway(id);
    if (res.status !== false) {
      console.log("paymentGateway res", res.url);
      if (res.url) {
        toast.success("Redirecting to Payment Gateway...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = res.url;
        });
      }
    } else {
      setLoading(false);
      if (res.url) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = res.url;
        }, 2000);
      }
    }
  };
  return (
    <>
      <div>
        {!data && <Header links={props.links} />}
        {loading2 ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {loading ? (
              <RedirectPage />
            ) : (
              <div className="contactus">
                <div className="container ">
                  <div className="row mx-0">
                    <div className={` ${data ? "" : "col-md-7"} pb-4 `}>
                      <h1 className="register-title py-4 fs-2 label-font">
                        {data
                          ? "International Sponsorship Details"
                          : "Please Confirm Your International Sponsorship Details"}
                      </h1>
                      <form>
                        <div className="row mb-4">
                          <div className="form-group ">
                            <label className="form-label col pl-0">
                              <span>Email *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              value={participantsData?.email}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-12 col-md-6">
                            <div className="col form-group">
                              <label className=" required label-font ps-1 mb-2">
                                Full Name *
                              </label>
                              <input
                                className="form-control"
                                placeholder="FullName"
                                value={participantsData?.full_name}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6  pl-2">
                            <div className="form-group">
                              <label className=" form-label col pl-0">
                                <span>Phone *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={participantsData?.phone}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12 col-md-6">
                            <div className="col form-group">
                              <label className=" required label-font ps-1 mb-2">
                                Sex *
                              </label>
                              <input
                                className="form-control"
                                value={participantsData?.sex
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6  pl-2">
                            <div className="form-group">
                              <label className=" form-label col pl-0">
                                <span>Religion *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={participantsData?.religion}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12  mb-3">
                          <div className="form-group">
                            <label className="form-label col pl-0">
                              <span>Address</span>
                            </label>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <label className="form-label col">
                                  Country *
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={participantsData?.country_name}
                                  disabled
                                />
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <label className="form-label col ">
                                  <span>State *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={participantsData?.state_name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <label>City *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={participantsData?.city_new_name}
                                  disabled
                                />
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <label>Street *</label>
                                <input
                                  type="text"
                                  className="form-control "
                                  value={participantsData?.street}
                                  disabled
                                />
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <label>Zip Code *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={participantsData?.zip_code}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-12 col-md-6 participant-f">
                            <div className="form-group">
                              <label className="form-label col pl-0">
                                <span>Company Name *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={participantsData?.company_name}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 participant-l pl-2">
                            <div className="form-group ">
                              <label className="form-label col pl-0">
                                <span>Designation *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                value={participantsData?.designation}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12 col-md-6  mb-4">
                            <label className="form-label col">
                              <span>Sponsorship type *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              value={participantsData?.sponsorship_type}
                              disabled
                            />
                          </div>
                          <div className="col-12 col-md-6  mb-4">
                            <label className="form-label col">
                              <span>Payment Method *</span>
                            </label>
                            {/* <input
                      type="text"
                      className="form-control "
                      value={participantsData?.payment_}
                      disabled
                    /> */}
                            <img
                              className="payment_logo img-fluid"
                              src={participantsData?.payment_icon_url}
                              alt={participantsData?.payment_method}
                            />
                          </div>
                        </div>
                        {!data && (
                          <div className=" d-flex justify-content-end">
                            <Button
                              as={Link}
                              to={`/edit-sponsor/${slug}`}
                              state={{ id: slug }}
                              className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1"
                              type="button"
                            >
                              Edit
                            </Button>
                            <Button
                              className={`contact-btn send-btn col-5 ${
                                loading ? "col-lg-3" : "col-lg-2"
                              } my-2 py-2 mx-1`}
                              type="button"
                              onClick={() => sendEmail(slug)}
                            >
                              {/* {loading ? "Please Wait" : "Confirm"} */}
                              Confirm
                            </Button>
                          </div>
                        )}
                      </form>
                    </div>
                    {!data && (
                      <div className=" col-md-5 py-4 px-0 ps-lg-4">
                        <img
                          src={Images.dharmalogo1}
                          alt="dharma"
                          className="img-fluid w-100"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {!data && (
          <>
            <Footer
              links={props.links}
              contacts={props.contacts}
              footerDescription={props.footerDescription}
            />
            <ToastContainer />
          </>
        )}
      </div>
    </>
  );
};
export default SponsorPreviewPage;
