import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../Common";
import { Button } from "react-bootstrap";
import { Api, Images } from "../../Constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const RedirectPage = (props) => {
  return (
    <>
      <div>
        {/* <Header links={props.links} /> */}
        <div className="container text-center">
          <div className="text-congrats mt-5 pt-md-5 mx-md-4 mb-5 pb-md-5">
            Redirecting to Payment Gateway <br />
            Please Wait
            <Spinner animation="border" role="status" className="ms-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
        {/* <Footer
          links={props.links}
          contacts={props.contacts}
          footerDescription={props.footerDescription}
        /> */}
      </div>
    </>
  );
};
export default RedirectPage;
