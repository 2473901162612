import "bootstrap/dist/css/bootstrap.min.css";
import "./ForgetPassword.css";
import { Header, Footer } from "../../Common";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Api } from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
const ForgetPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onsubmit = async (data) => {
    // console.log("Dara", data.email);
    // const postData = data.email;
    // console.log("d", data);
    const res = await Api.postForgetPassword(data);
    console.log("res", res);
    if (res) {
      toast.success("Please Check Your Email! Redirecting...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      //   setLoginError(true);
      console.log("error", res);
    }
  };
  return (
    <>
      <Header links={props.links} />
      <div className="container ">
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="  row justify-content-center my-4">
            <div className=" forget_password col  col-md-6 col-sm-8 my-3 mx-3">
              <h3>Forgot Password?</h3>
              <p>Please enter the email to reset the password</p>

              {/* <input
                className="form-control"
                type="email"
                placeholder="Email"
              /> */}
              <input
                {...register("email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
                type="text"
                className="form-control"
              />

              <button
                type="submit"
                className="btn btn-danger btn_control mt-4  mb-2   float-end"
              >
                Retrieve{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </>
  );
};
export default ForgetPassword;
