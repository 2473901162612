import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import { Api, Images } from "../../Constants";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { IoLogoTwitter } from "react-icons/io";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { FaUser } from "react-icons/fa6";
import "./Loader.css";
import { Button, NavItem, Spinner } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirect } from "../../Pages/Reducer/routesSlice";
import { logout } from "../../Pages/Reducer/userSlice";
import {
  MutatingDots,
  InfinitySpin,
  Puff,
  Bars,
  ThreeDots,
  Circles,
  Rings,
} from "react-loader-spinner";
const Loader = () => {
  return (
    <>
      <div className={`loader-section`}>
        {/* <Spinner
          animation="border"
          role="status"
          className="p-4 red-spinner"
        ></Spinner> */}
        <Circles
          visible={true}
          height="80"
          width="80"
          color="#970d0d"
          secondaryColor="#970d0d"
          radius="12.5"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </>
  );
};

export default Loader;
