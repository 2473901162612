import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../Common";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Api, Images } from "../../Constants";
import ReCAPTCHA from "react-google-recaptcha";
import "./ContactUs.css";
import { Loader } from "../../Common/Loader";

const Feedback = (props) => {
  const { from, handleResend } = props;
  return (
    <div className="d-flex flex-column align-items-center py-3">
      <img src={Images.success} className="img-fluid pb-3" />
      <p className="fs-5 text-center">
        {`Hello`}{" "}
        <span className="text-capitalize fw-bold">{from.toLowerCase()}</span>
        {`, your message has been submitted successfully.`}
      </p>
      <button className="btn btn-success fs-5 re-send" onClick={handleResend}>
        Send Again
      </button>
    </div>
  );
};

const ContactUs = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [inquirer, setInquirer] = useState("");
  const [loading, setLoading] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const onSubmit = async (data) => {
    if (captcha) {
      const res = await Api.postContactUs(data);
      console.log("res", res);
      if (res.status === true) {
        localStorage.setItem(
          "inquiry_detail",
          JSON.stringify({ from: data.fullname })
        );
        setInquirer(data.fullname);
      }
    } else {
      setCaptchaError(true);
    }
  };
  function onChange(value) {
    // console.log("Captcha value:", value);
    setCaptcha(value);
  }
  // const handleIFrame = (e) => setLoading(false);
  const handleResend = () => {
    setInquirer("");
    localStorage.removeItem("inquiry_detail");

    reset();
  };

  const getInquiry = () => {
    setLoading(true);
    const detail = localStorage.getItem("inquiry_detail");
    if (!detail) return setLoading(false);
    const { from } = JSON.parse(detail);
    setInquirer(from);
    setLoading(false);
  };

  useEffect(() => {
    getInquiry();
  }, []);

  return (
    <div>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="contactus">
            <div className="container ">
              <div className="row mx-0">
                <h1 className="contactus-title py-4 fs-2 label-font">
                  CONTACT US
                </h1>
                {inquirer ? (
                  <div className=" col-md-6 pb-4">
                    <Feedback from={inquirer} handleResend={handleResend} />
                  </div>
                ) : (
                  <>
                    <div className=" col-md-6 pb-4">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">
                              Full Name *
                            </label>
                            <input
                              className={`form-control input-font ${
                                errors.fullname ? "is-invalid" : ""
                              }`}
                              required
                              {...register("fullname")}
                              type="text"
                              placeholder="Full Name"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Full Name is required."
                                )
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {/* {errors.fullname && (
                        <div className="invalid-feedback">
                          Full Name is required.
                        </div>
                      )} */}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">
                              Phone Number *
                            </label>
                            <input
                              pattern="[0-9]{10}"
                              className={`form-control input-font ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              required
                              {...register("phone")}
                              type="text"
                              placeholder="Phone Number"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Please enter a valid 10-digit phone number."
                                )
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {/* {errors.phone && (
                        <div className="invalid-feedback">
                          Phone Number is required.
                        </div>
                      )}                      */}
                          </div>

                          <div className="col form-group">
                            <label className="label-font ps-1">Email *</label>
                            <input
                              className={`form-control input-font ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              required
                              {...register("email")}
                              type="email"
                              placeholder="Email"
                              onInvalid={(e) =>
                                e.target.setCustomValidity("Email is required.")
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {/* {errors.email && (
                        <div className="invalid-feedback">
                          Email is required.
                        </div>
                      )} */}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">Address *</label>
                            <input
                              className={`form-control input-font ${
                                errors.address ? "is-invalid" : ""
                              }`}
                              required
                              {...register("address")}
                              type="text"
                              placeholder="Address"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Address is required."
                                )
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {/* {errors.address && (
                        <div className="invalid-feedback">
                          Address is required.
                        </div>
                      )} */}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">Subject *</label>
                            <input
                              className={`form-control input-font ${
                                errors.subject ? "is-invalid" : ""
                              }`}
                              required
                              {...register("subject")}
                              type="text"
                              placeholder="Subject"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Subject is required."
                                )
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            />
                            {errors.subject && (
                              <div className="invalid-feedback">
                                Subject is required.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col form-group">
                            <label className="label-font ps-1">Message *</label>
                            <textarea
                              className={`form-control input-font ${
                                errors.message ? "is-invalid" : ""
                              }`}
                              required
                              {...register("message")}
                              placeholder="Leave a Message Here"
                              rows="4"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Message is required."
                                )
                              }
                              onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                            ></textarea>
                            {/* {errors.message && (
                        <div className="invalid-feedback">
                          Message is required.
                        </div>
                      )} */}
                          </div>
                        </div>
                        <div className="g-recaptcha ">
                          <ReCAPTCHA
                            // sitekey="6LfFnjUfAAAAAH0tqwoJDI27iaF1VFCbDkN7F3Bn"
                            sitekey="6LebMUIpAAAAAKp6UDsAamop6_5aUQanmr2bQAQ6"
                            onChange={onChange}
                            size="normal"
                          />
                        </div>
                        {captchaError && (
                          <span className="text-danger">
                            Captcha is required
                          </span>
                        )}

                        <div className=" d-flex justify-content-end">
                          <Button
                            className="contact-btn send-btn px-4"
                            type="submit"
                          >
                            Send
                          </Button>
                        </div>
                      </form>
                    </div>
                    <div className=" col-md-6 py-4 px-0 px-lg-4">
                      {/* <img src={Images.dharmalogo} alt="dharma" className="img-fluid w-100" /> */}

                      <iframe
                        // className="map-iframe img-fluid "
                        title="mapFrame"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.9471396149584!2d85.37237297582801!3d27.718918324980454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1b48aba75f4f%3A0xf0ea8f4c2b765e31!2sDHARMA%20TELEVISION%20HD!5e0!3m2!1sen!2snp!4v1700735368342!5m2!1sen!2snp"
                        width="100%"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </div>
  );
};

export default ContactUs;
