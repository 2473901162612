//Local

// const FILE_URL = 'http://127.0.0.1:8000/storage/uploads';
// const BASE_URL = "http://127.0.0.1:8000/api/v1";
// const IMAGE_URL = "https://127.0.0.1:8000";

// Live
const FILE_URL = "https://api.dharmaideal.org/storage/uploads";
const BASE_URL = "https://api.dharmaideal.org/api/v1";
const IMAGE_URL = "https://api.dharmaideal.org";
// const FILE_URL = "https://apidharma.innepal.biz/storage/uploads";
// const BASE_URL = "https://apidharma.innepal.biz/api/v1";
// const IMAGE_URL = "https://apidharma.innepal.biz/";

//Event Registration
const GET_EVENT_DETAIL_URL = "https://apievent.dharmaideal.org/api_v2/";
const REGISTER_BASE_URL = "https://api.dharmaideal.org/api/v1/";

export default {
  FILE_URL,
  BASE_URL,
  IMAGE_URL,
  REGISTER_BASE_URL,
  GET_EVENT_DETAIL_URL,
};
