import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../Common";
import {} from "react-router-dom";
import { Api, Images } from "../../Constants";
import { Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../../Common/Loader";

const TravelAssistancePreviewPage = (props) => {
  // react redux useSelector hook to access the state
  const user = useSelector((state) => state.user);

  const userDetails = user && user.isLoggedIn ? user.details : null;

  const location = useLocation();

  const [slug, setSlug] = useState(null);
  const [participantsData, setParticipantData] = useState();
  const [loading, setLoading] = useState(true);
  const pathName = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //     const id = location.state && location.state.id;
  //     window.scrollTo(0, 0);
  //     setSlug(id)
  //     getPreviewData(id);
  // }, [location.state]);
  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    window.scrollTo(0, 0);
    setSlug(id);
    getPreviewData(id);
  }, [location.state && location.state.id]);

  const getPreviewData = async (id) => {
    setLoading(true);
    const res = await Api.getTravelAssistance(id);
    if (res.status) {
      console.log(res);
      setParticipantData(res.assitance_in_travel_detail);
      // setParticipantData(stateOptions)
    } else {
      // setParticipants({})
    }
    setLoading(false);
  };

  // const sendEmail = async (id) => {
  //     const res = await Api.sendEmailToDonorMember(id);
  //     if (res) {
  //         const activateRes = await Api.sendEmailtoActivateMember(id);
  //         if (activateRes) {
  //             paymentGateway(id)
  //             // navigate(`/donation-confirmation/`, { state: { id: id } });

  //         }
  //     } else {
  //         toast.error("Something went wrong. Please try again!", {
  //             position: toast.POSITION.TOP_RIGHT,
  //         });
  //     }

  // }

  const sendEmail = async (id) => {
    const res = await Api.sendEmailToTravelAssistance(id);
    if (res) {
      // navigate(`/donation-confirmation/`, { state: { id: id } });
      navigate(`/travel-assistance-confirmation/${slug}`);
    } else {
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-lg">
            <div className="">
              <div className="row form-group mx-0 mb-4">
                <h1 className="register-title py-4 fs-2 label-font">
                  Please Confirm Your Donation Details
                </h1>

                <div className="col-lg-8">
                  <div className="row">
                    {userDetails?.email && (
                      <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                        <label className="form-label col pl-0">
                          <span>Email</span>
                        </label>
                        <div className="preview-data">{userDetails?.email}</div>
                      </div>
                    )}

                    {userDetails?.full_name && (
                      <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                        <label className="form-label col pl-0">
                          <span>Full Name</span>
                        </label>
                        <div className="preview-data">
                          {userDetails?.full_name}
                        </div>
                      </div>
                    )}

                    {userDetails?.sex && (
                      <div className="col-5 col-md-4 participant-f my-1 my-lg-2 form-group">
                        <label className="required form-label col pl-0">
                          <span>Sex *</span>
                        </label>
                        <div className="preview-data  text-capitalize">
                          {userDetails?.sex}
                        </div>
                      </div>
                    )}

                    {userDetails?.religion && (
                      <div className="col-7 col-md-8 participant-l pl-2 my-1 my-lg-2">
                        <label className="form-label col pl-0">
                          <span>Religion*</span>
                        </label>
                        <div className="preview-data  text-capitalize">
                          {userDetails?.religion}
                        </div>
                      </div>
                    )}

                    {userDetails?.phone && (
                      <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                        <label className="required form-label col pl-0">
                          <span>Phone *</span>
                        </label>
                        <div className="preview-data">{userDetails?.phone}</div>
                      </div>
                    )}

                    {userDetails?.country_name && (
                      <div className="col-12 participant-f my-1 my-lg-2">
                        <label className="form-label col pl-0">
                          <span>Address</span>
                        </label>
                        <div className="row mx-0">
                          <div className="col-12 col-md-6 px-0 pe-md-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Country
                            </label>
                            <div className="preview-data text-capitalize">
                              {userDetails?.country_name}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 px-0 mt-3 my-md-0">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              State
                            </label>
                            <div className="preview-data text-capitalize">
                              {userDetails?.state_name
                                ? userDetails?.state_name
                                : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              City
                            </label>
                            <div className="preview-data text-capitalize">
                              {userDetails?.city ? userDetails?.city : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Street
                            </label>
                            <div className="preview-data text-capitalize">
                              {userDetails?.street ? userDetails?.street : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 ps-xl-3 pe-xl-0 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Zip Code
                            </label>
                            <div className="preview-data">
                              {userDetails?.zip_code
                                ? userDetails?.zip_code
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                      <label className="form-label col pl-0">
                        <span>Arrival Date</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.arrival_date}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                      <label className="form-label col pl-0">
                        <span>Departure Date</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.departure_date}
                      </div>
                    </div>

                    <div className="col-12 my-1 my-lg-2 form-group">
                      <label className="form-label col pl-0">
                        <span>Mode Of Transportation</span>
                      </label>
                      <div className="preview-data text-capitalize">
                        {participantsData?.mode_of_transportation}
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                    <Button
                      as={Link}
                      to={{
                        pathname: `/edit-travel-details/${slug}`,
                      }}
                      className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1"
                      type="button"
                    >
                      Edit
                    </Button>

                    <Button
                      className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1"
                      type="button"
                      onClick={() => sendEmail(slug)}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
                <div className=" col-lg-4 d-none d-lg-block">
                  <img
                    src={Images.dharmalogo1}
                    alt="dharma"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </>
  );
};

export default TravelAssistancePreviewPage;
