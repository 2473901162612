import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../Common";
import { useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Register.css";
import { FormSelect } from "react-bootstrap";
import { Api, Images } from "../../Constants";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ParticipantCountry from "./ParticipantCountry";
import { useSelector, useDispatch } from "react-redux";
import { updateRoute } from "../Reducer/routesSlice";
import { Loader } from "../../Common/Loader";

const accomodationOptions = [
  {
    id: "1",
    value: "free",
    label: "Free",
  },
  {
    id: "2",
    value: "hotel",
    label: "Hotel",
  },
];

const religionOptions = [
  {
    id: "1",
    value: "buddhism",
    label: "Buddhism",
  },
  {
    id: "2",
    value: "hinduism",
    label: "Hinduism",
  },
  {
    id: "3",
    value: "islam",
    label: "Islam",
  },
  {
    id: "4",
    value: "christainity",
    label: "Christainity",
  },
  {
    id: "5",
    value: "sikhism",
    label: "Sikhism",
  },
  {
    id: "6",
    value: "others",
    label: "Others",
  },
  {
    id: "7",
    value: "prefer not to say",
    label: "Prefer Not to Say",
  },
];

const genderOption = [
  {
    id: "1",
    value: "male",
    label: "Male",
  },
  {
    id: "2",
    value: "female",
    label: "Female",
  },
  {
    id: "3",
    value: "other",
    label: "Others",
  },
];

const visaSupportOptions = [
  {
    id: "1",
    value: "yes",
    label: "Yes",
  },
  {
    id: "2",
    value: "no",
    label: "No",
  },
];

const Register = (props) => {
  const user = useSelector((state) => state.user);
  const userDetails = user && user.isLoggedIn ? user.current : null;
  const route = useSelector((state) => state.route);

  const dispatch = useDispatch();

  const [participants, setParticipants] = useState([{ id: 1 }]);

  const [participattionDays, setParticipationDays] = useState([]);
  const [accomodation, setAccomodation] = useState(accomodationOptions);

  const [cardDivHeight, setCardDivHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [religion, setReligion] = useState(religionOptions);
  const [gender, setGenderOption] = useState(genderOption);
  const [visaSupport, setVisaSupport] = useState(visaSupportOptions);

  const [paymentType, setPaymentType] = useState("card_payment"); // Set the default value

  const [selectedAccomodation, setSelectedAccomodation] = useState();
  const [selectedGender, setSelectedGender] = useState(gender[1].value);
  const [selectedReligion, setSelectedReligion] = useState();
  const [selectedVisaSupport, setSelectedVisaSupport] = useState();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [agree, setAgree] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const [applicationId, setApplicationId] = useState();
  const [slug, setSlug] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const {
    clearErrors,
    getValues,
    setValue,
    register,
    handleSubmit,
    unregister,
    setError,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const pathName = useParams();
  const location = useLocation();

  const setCardHeight = () => {
    // Get the height of the card-div
    const cardDivElement = document.querySelectorAll(".box");
    const lastAppendedBox = cardDivElement[cardDivElement.length - 1];

    const height = lastAppendedBox ? lastAppendedBox.offsetHeight - 2 : 0;
    // Set the height of addBtn-div to match card-div
    setCardDivHeight(height);

    const cardDivs = document.querySelectorAll(".box");

    let newMaxHeight = 0;

    cardDivs.forEach((cardDiv) => {
      const height = cardDiv.offsetHeight; // Include padding and border
      newMaxHeight = Math.max(newMaxHeight, height);
    });

    setMaxHeight(newMaxHeight);
  };

  const getProgramList = async () => {
    setLoading2(true);
    const res = await Api.getProgrammes();
    if (!res) return;
    setParticipationDays(res?.event_details);
    setLoading2(false);
  };

  // callback function to store the country list
  const handleCountryChange = (index, id) => {
    // Update a participant country with the given index
    setSelectedCountry((prev) => ({ ...prev, [index]: id }));
  };

  // callback function to store the state list
  const handleStateChange = (index, id) => {
    // Update a participant's state with the given index
    setSelectedState((prev) => ({ ...prev, [index]: id }));
  };

  // callback function to store the city list
  const handleCityChange = (index, id) => {
    // Update a participant's city with the given index
    setSelectedCity((prev) => ({ ...prev, [index]: id }));
  };

  // function to get the participant details and display in the edit pariticipant form
  const getParticipantsDetail = async (id) => {
    setLoading(true);
    const res = await Api.getParticiapantDataFromRegistrationId(id);
    if (res.status) {
      setParticipants(res.participant_detail.participant_detail);

      // set value using setValue of react-hook-form for the fields
      const selectedEventIds = res.participant_detail.event_details.map(
        (event) => event.id
      );
      setValue(`event_ids`, selectedEventIds);
      setValue(`accomodation`, res.participant_detail.accomodation);

      res.participant_detail.participant_detail.forEach((elem, index) => {
        setValue(`full_name[${index}]`, elem.full_name);
        setValue(`user_uuid[${index}]`, elem.user_id);
        setValue(`sex[${index}]`, elem.sex);
        setValue(`religion[${index}]`, elem.religion);
        setValue(`phone[${index}]`, elem.phone);
        setValue(`email[${index}]`, elem.email);
        setValue(`country_id[${index}]`, elem.country_id);
        setValue(`state_id[${index}]`, elem.state_id);
        // setValue(`city_id[${index}]`, elem.city_id);
        setValue(`city_new_name[${index}]`, elem.city_new_name);
        setValue(`street[${index}]`, elem.street);
        setValue(`zip_code[${index}]`, elem.zip_code);
        setValue(`visa[${index}]`, elem.visa);

        setSelectedCountry((prev) => ({ ...prev, [index]: elem.country_id }));
        setSelectedState((prev) => ({ ...prev, [index]: elem.state_id }));
        // setSelectedCity((prev) => ({ ...prev, [index]: elem.city_id }));
        setSelectedCity((prev) => ({ ...prev, [index]: elem.city_new_name }));
      });
    } else {
      // setParticipants({})
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("pathname.slug", pathName.id);

    if (pathName.id !== undefined) {
      setSlug(pathName.id);

      getParticipantsDetail(pathName.id);

      console.log("pathName", pathName);
    }
    setCardHeight();
    getProgramList();
  }, [pathName.id, pathName.id === undefined && errors]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     if (location.state) {
  //         setSlug(location.state.id)
  //         getParticipantsDetail(location.state.id);
  //     }
  //     setCardHeight();
  //     getProgramList();
  // }, [location.state, location.state === null && errors]);

  // adds the participant details to the participants array
  const handleAddParticipant = () => {
    setParticipants((prevParticipants) => {
      const newParticipants = [...prevParticipants, { id: Date.now() }];
      return newParticipants;
    });
    setCardHeight();
  };

  //   deletes the participants with the index from the participants array
  const handleDeleteCard = (index) => {
    setParticipants((prevParticipants) => {
      const updatedParticipants = [...prevParticipants];
      updatedParticipants.splice(index, 1);
      setParticipants(updatedParticipants);
      return updatedParticipants;
    });

    // set value for the participants after the participant with the index has been removed
    var totalParticipants = participants.length;
    for (let i = index; i < totalParticipants; i++) {
      setValue(`full_name[${i}]`, getValues(`full_name[${i + 1}]`));
      setValue(`sex[${i}]`, getValues(`sex[${i + 1}]`));
      setValue(`religion[${i}]`, getValues(`religion[${i + 1}]`));
      setValue(`phone[${i}]`, getValues(`phone[${i + 1}]`));
      setValue(`email[${i}]`, getValues(`email[${i + 1}]`));
      setValue(`country_id[${i}]`, getValues(`country_id[${i + 1}]`));
      setValue(`state_id[${i}]`, getValues(`state_id[${i + 1}]`));
      // setValue(`city_id[${i}]`, getValues(`city_id[${i + 1}]`));
      setValue(`city_new_name[${i}]`, getValues(`city_new_name[${i + 1}]`));
      setValue(`street[${i}]`, getValues(`street[${i + 1}]`));
      setValue(`zip_code[${i}]`, getValues(`zip_code[${i + 1}]`));
      setValue(`visa[${i}]`, getValues(`visa[${i + 1}]`));

      const selectedCountryId = getValues(`country_id[${i + 1}]`);
      const selectedStateId = getValues(`state_id[${i + 1}]`);
      // const selectedCityId = getValues(`city_id[${i + 1}]`);
      const cityNames = getValues(`city_new_name[${i + 1}]`);

      setSelectedCountry((prev) => ({ ...prev, [i]: selectedCountryId }));
      setSelectedState((prev) => ({ ...prev, [i]: selectedStateId }));
      // setSelectedCity((prev) => ({ ...prev, [i]:selectedCityId}));
      setSelectedCity((prev) => ({ ...prev, [i]: cityNames }));
    }

    // Clear values for the last participant
    unregister([
      `full_name[${totalParticipants - 1}]`,
      `sex[${totalParticipants - 1}]`,
      `religion[${totalParticipants - 1}]`,
      `email[${totalParticipants - 1}]`,
      `phone[${totalParticipants - 1}]`,
      `country_id[${totalParticipants - 1}]`,
      `state_id[${totalParticipants - 1}]`,
      // `city_id[${totalParticipants - 1}]`,
      `city_new_name[${totalParticipants - 1}]`,
      `street[${totalParticipants - 1}]`,
      `zip_code[${totalParticipants - 1}]`,
      `visa[${totalParticipants - 1}]`,
    ]);
    setCardHeight();

    toast.success("Participant Deleted Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // const handlePaymentChange = (value) => {
  //     setPaymentType(value);
  // };

  // function to handle the submit button
  // checks if the user clicked on the agree to privacy policy and terms and condition checkbox
  const handleAgreeCheck = () => {
    setChecked(!checked);
    if (checked) {
      setAgree(false);
    } else {
      setAgree(true);
    }
  };

  // function to handle the submit button
  const onSubmit = (data) => handleFormSubmit(data);

  const handleFormSubmit = async (data) => {
    const postData = {
      ...data,
      payment_method: "khalti",
    };
    let res;
    // post api call to register the user
    if (!slug) {
      res = await Api.postRegister(postData);
    } else {
      // post api call to update registered user
      res = await Api.updateRegister(postData, slug);
    }

    if (res.status) {
      // if the post api returns success sets the application id and then navigates to the preview page
      setApplicationId(res.application_id);
      navigate(`/preview-page/${res.application_id}`);
      // navigate(`/preview-page/`, { state: { id: res.application_id } });
    } else {
      // if the post api returns error then displays the error message
      if (res.message) {
        const error = res.message;
        // sets error message for the fields using the setError of react-hook-form
        Object.keys(error).forEach((field) => {
          const errorMessage = error[field];

          // Check if the error message exists and has a length
          if (errorMessage && errorMessage.length > 0) {
            setError(field, {
              type: "manual",
              message: errorMessage, // Assuming errorMessage is a string or an array
            });
          }
        });
      }
      // call setCardHeight to set the height of the addBtn-div if the error occurs
      setTimeout(() => {
        setCardHeight();
      }, 30);
    }
  };

  const checkEmail = async (e) => {
    const inputValue = e.target.value;
    const res = await Api.checkEmail(inputValue);

    if (res.status !== false) {
      console.log("res.data", res.data);
      toast.success(
        "Participant with this email already exists! Please login.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      // Dispatch the updateRoute action before navigating
      dispatch(updateRoute(window.location.pathname));

      // navigate to the login route with state
      setTimeout(() => {
        navigate(`/login`, { state: { email: inputValue } });
      }, 2000);
    } else {
      console.log("status", res);
    }
  };

  return (
    <div>
      <Header links={props.links} />
      {loading2 ? (
        <Loader />
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="register">
                <div className="container ">
                  <div className="row mx-0">
                    <div className=" col-md-12 py-3 mx-lg-2">
                      <h1 className="register-title py-4 fs-3 label-font">
                        International Events Registration Form
                      </h1>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-4">
                          <div className="col-12 mb-1 my-lg-2 form-group">
                            <label className="form-label col pl-0">
                              Select Programme to Participate *
                            </label>
                            <div className="my-2 d-flex flex-column flex-lg-row">
                              {participattionDays?.map((elem, index) => (
                                <span key={index} className="me-2 my-1 my-lg-0">
                                  <input
                                    type="checkbox"
                                    className="mx-2"
                                    // onChange = {onChangeProgram}
                                    value={elem.id}
                                    {...register(`event_ids`, {
                                      required: true,
                                    })}
                                  />
                                  <span>{elem.name}</span>
                                </span>
                              ))}
                            </div>
                            {errors.event_ids && (
                              <div className="text-danger">
                                Programme is required.
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6 col-xl-4 my-1 my-lg-2 form-group">
                            {/* <label className="label-font ps-1">Accomodation *</label> */}
                            <label className="form-label col pl-0">
                              <span>Accomodation *</span>
                            </label>
                            <select
                              {...register("accomodation", { required: true })}
                              className={`form-control ${
                                errors.accomodation && "border-danger"
                              }`}
                              value={selectedAccomodation}
                              onChange={(e) =>
                                setSelectedAccomodation(e.target.value)
                              }
                            >
                              <option value="" hidden>
                                Choose option
                              </option>
                              {accomodation.map((elem) => (
                                <option key={elem.value} value={elem.value}>
                                  {elem.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.accomodation && (
                            <div className="text-danger">
                              Accomodation is required.
                            </div>
                          )}
                        </div>

                        <div className="row mb-4">
                          {participants.map((elem, index) => (
                            <div
                              key={index}
                              className="card-div col-12 col-lg-6"
                              id={elem.id}
                            >
                              <div
                                className="participants-title d-flex justify-content-between align-items-center"
                                id="participants-title-id"
                              >
                                {index === 0 ? (
                                  <h4 className="register-title pt-2 label-font col-9">
                                    Main Participant
                                  </h4>
                                ) : (
                                  <h4 className="register-title pt-2 label-font col-9">
                                    Participant {index}
                                  </h4>
                                )}
                                {!slug && (
                                  <div
                                    className={`deleteParticipants_div col text-end ${
                                      participants.length > 1 && index !== 0
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    <button
                                      className="btn btn-danger delete_participants"
                                      type="button"
                                      onClick={() => handleDeleteCard(index)}
                                    >
                                      x
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div
                                className="box"
                                style={{ minHeight: `${maxHeight}px` }}
                              >
                                <div className="row">
                                  <div className="col-12 participant-f form-group mb-3">
                                    <label className="form-label col pl-0">
                                      <span>Email *</span>
                                    </label>
                                    {index === 0 ? (
                                      <input
                                        {...register(`email[${index}]`, {
                                          required: "Valid email is required",
                                          pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Valid email is required",
                                          },
                                        })}
                                        onBlur={checkEmail}
                                        type="text"
                                        className={`form-control ${
                                          errors?.email?.[index] &&
                                          "border-danger"
                                        }`}
                                        placeholder="Email"
                                      />
                                    ) : (
                                      <input
                                        {...register(`email[${index}]`, {
                                          required: "Valid email is required",
                                          pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Valid email is required",
                                          },
                                        })}
                                        // onBlur={checkEmail}
                                        type="text"
                                        className={`form-control ${
                                          errors?.email?.[index] &&
                                          "border-danger"
                                        }`}
                                        placeholder="Email"
                                      />
                                    )}

                                    {errors?.email?.[index] && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: errors.email[index].message,
                                        }}
                                        className="text-danger"
                                      />
                                    )}
                                  </div>

                                  <div className="col-12 participant-f form-group mb-3">
                                    <label className="form-label col pl-0">
                                      <span>Full Name *</span>
                                    </label>
                                    <input
                                      {...register(`full_name[${index}]`, {
                                        required: "Full Name is required",
                                        pattern: {
                                          value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                                          message: "Invalid full name",
                                        },
                                      })}
                                      type="text"
                                      className={`form-control ${
                                        errors?.full_name?.[index] &&
                                        "border-danger"
                                      }`}
                                      placeholder="Full Name"
                                    />
                                    {errors?.full_name?.[index] && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            errors.full_name[index].message,
                                        }}
                                        className="text-danger"
                                      />
                                    )}
                                  </div>

                                  {slug && (
                                    <input
                                      {...register(`user_uuid[${index}]`, {})}
                                      hidden
                                      type="text"
                                    />
                                  )}

                                  <div className="col-5 col-md-4 participant-f mb-3">
                                    <div className="form-group">
                                      <label className="required form-label col pl-0">
                                        <span>Sex *</span>
                                      </label>
                                      <select
                                        {...register(`sex[${index}]`, {
                                          required: "Sex is required.",
                                        })}
                                        className={`form-control ${
                                          errors?.sex?.[index] &&
                                          "border-danger"
                                        }`}
                                        defaultValue={selectedGender}
                                        onChange={(e) =>
                                          setSelectedGender(e.target.value)
                                        }
                                      >
                                        {/* <option value="">Choose option</option> */}
                                        {gender.map((elem) => (
                                          <option
                                            key={elem.value}
                                            value={elem.value}
                                          >
                                            {elem.label}
                                          </option>
                                        ))}
                                      </select>
                                      {errors?.sex?.[index] && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: errors.sex[index].message,
                                          }}
                                          className="text-danger"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-7 col-md-8 participant-l pl-2 mb-3">
                                    <label className="form-label col pl-0">
                                      <span>Religion*</span>
                                    </label>
                                    <select
                                      {...register(`religion[${index}]`, {
                                        required: "Religion is required.",
                                      })}
                                      className={`form-control ${
                                        errors?.religion?.[index] &&
                                        "border-danger"
                                      }`}
                                      // value={selectedReligion}
                                      onChange={(e) =>
                                        setSelectedReligion(e.target.value)
                                      }
                                    >
                                      <option value="" hidden>
                                        Choose option
                                      </option>
                                      {religion.map((elem) => (
                                        <option
                                          key={elem.value}
                                          value={elem.value}
                                        >
                                          {elem.label}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.religion?.[index] && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            errors.religion[index].message,
                                        }}
                                        className="text-danger"
                                      />
                                    )}
                                  </div>

                                  <div className="col-12 col-md-6 participant-f mb-3">
                                    <div className="form-group">
                                      <label className="required form-label col pl-0">
                                        <span>Phone *</span>
                                      </label>
                                      <input
                                        {...register(`phone[${index}]`, {
                                          required:
                                            "Valid phone number is required",
                                          pattern: {
                                            // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                            value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                            message:
                                              "Valid phone number is required",
                                          },
                                        })}
                                        type="text"
                                        className={`form-control ${
                                          errors?.phone?.[index] &&
                                          "border-danger"
                                        }`}
                                        placeholder="Phone Number"
                                      />
                                      {errors?.phone?.[index] && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: errors.phone[index].message,
                                          }}
                                          className="text-danger"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="col-12 col-md-7 participant-l pl-2 mb-3">
                                                        <div className="form-group ">
                                                            <label className="form-label col pl-0"><span>Email *</span></label>
                                                            <input
                                                                {...register(`email[${index}]`, {
                                                                    required: 'Valid email is required',
                                                                    pattern: {
                                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                                        message: 'Valid email is required'
                                                                    }
                                                                })}
                                                                type="text"
                                                                className={`form-control ${errors?.email?.[index] && 'border-danger'}`}
                                                                placeholder="Email"
                                                            />
                                                            {errors?.email?.[index] &&
                                                                <div dangerouslySetInnerHTML={{ __html: errors.email[index].message }} className='text-danger' />
                                                            }

                                                        </div>
                                                    </div> */}
                                  <div className="col-12 participant-f mb-3">
                                    <div className="form-group">
                                      <label className="form-label col pl-0">
                                        <span>Address</span>{" "}
                                        {index === 0 ? (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: "Roboto-Regular",
                                            }}
                                          >
                                            {" "}
                                            (will be billed to this address)
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                      <ParticipantCountry
                                        key={elem.id}
                                        index={index}
                                        onCountryChange={handleCountryChange}
                                        onStateChange={handleStateChange}
                                        onCityChange={handleCityChange}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                        selectedCountryParent={
                                          selectedCountry[index]
                                        }
                                        selectedStateParent={
                                          selectedState[index]
                                        }
                                        selectedCityParent={selectedCity[index]}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-8 participant-f mb-3">
                                    <div className="form-group ">
                                      <label className="form-label pl-0">
                                        <span>Visa Support Letter? *</span>
                                      </label>
                                      <div className="col-6 col-md-6 pe-md-2">
                                        <select
                                          {...register(`visa[${index}]`)}
                                          className="form-control"
                                          defaultValue={
                                            visaSupport.find(
                                              (visaSupport) =>
                                                visaSupport.value === "no"
                                            )?.value || ""
                                          }
                                          onChange={(e) => {
                                            setSelectedVisaSupport(
                                              e.target.value
                                            );
                                            // handleCityChange(e.target.value)
                                          }}
                                        >
                                          {visaSupport.map(
                                            (elem, indexelem) => (
                                              <option
                                                key={elem.value}
                                                value={elem.value}
                                              >
                                                {elem.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {!slug && (
                            <div className="addBtn-div col-12 col-lg-6">
                              <div
                                className="participants-title d-flex justify-content-between align-items-center"
                                id="participants-title-id"
                              >
                                <h4
                                  className="register-title pt-2 label-font col-9"
                                  style={{ visibility: "hidden" }}
                                >
                                  Participant{" "}
                                </h4>
                              </div>
                              <div
                                className="button-container"
                                id="add_participant"
                                style={{ height: cardDivHeight }}
                                onClick={handleAddParticipant}
                              >
                                <span className="button-text">
                                  +<br />
                                  Participant
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className="row mb-4">
                                    <div className="col-12 col-md-6 col-xl-4 my-1 my-lg-2 form-group">

                                        <div className="form-group">
                                            <label className="label-font ps-1">Payment Mode *</label>
                                            <div className="form-radio-group d-flex flex-row" id="form-radio-group">
                                                <div className="form-radio-item payment-div" data-toggle="modal" data-target="#paymentGatewayModal" title="Card Payment" onClick={() => handlePaymentChange("khalti")}
                                                >
                                                    <input
                                                        type="radio"
                                                        value="khalti"
                                                        id="khalti"
                                                        name="payment_type"
                                                        checked={paymentType === "khalti"}
                                                        onChange={() => { }} // Set to empty function to prevent warning
                                                    />
                                                    <img className="payment_logo img-fluid" src={Images.khalti} alt="Khalti" />
                                                </div>

                                                <div className="form-radio-item payment-div" data-toggle="modal" data-target="#paymentGatewayModal" title="Card Payment" onClick={() => handlePaymentChange("esewa")}>
                                                    <input
                                                        type="radio"
                                                        value="esewa"
                                                        id="esewa"
                                                        name="payment_type"
                                                        checked={paymentType === "esewa"}
                                                        onChange={() => { }} // Set to empty function to prevent warning
                                                    />
                                                    <img className="payment_logo img-fluid" src={Images.esewa} alt="Khalti" />
                                                </div>

                                                <div className="form-radio-item payment-div" data-toggle="modal" data-target="#paymentGatewayModal" title="Card Payment" onClick={() => handlePaymentChange("card_payment")}>
                                                    <input
                                                        type="radio"
                                                        value="card_payment"
                                                        id="card_payment"
                                                        name="payment_type"
                                                        checked={paymentType === "card_payment"}
                                                        onChange={() => { }} // Set to empty function to prevent warning
                                                    />
                                                    <img className="payment_logo img-fluid" src={Images.cardPayment} alt="Card Payment" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                        {!slug ? (
                          <>
                            <div className="mb-4">
                              <input
                                type="checkbox"
                                className="mx-2"
                                onClick={handleAgreeCheck}
                                {...register(`terms_condition`, {
                                  required: true,
                                })}
                              />
                              <span>
                                I agree to{" "}
                                <Link to="/terms-and-condition" target="_blank">
                                  Terms and Condition
                                </Link>{" "}
                                and{" "}
                                <Link to="/privacy-policy" target="_blank">
                                  Privacy Policy
                                </Link>
                                .
                              </span>
                            </div>

                            <div className=" d-flex justify-content-end mt-3">
                              <Button
                                className="contact-btn send-btn px-4"
                                type="submit"
                                disabled={!agree}
                              >
                                Register
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className=" d-flex justify-content-end mt-3">
                            <Button
                              className="contact-btn send-btn px-4"
                              type="submit"
                            >
                              Register
                            </Button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </div>
  );
};

export { Register };
