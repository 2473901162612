import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../Common";
import {} from "react-router-dom";
import { Api } from "../../Constants";
import { Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RedirectPage from "../SponsorPreviewPage/RedirectPage";
import { useDispatch } from "react-redux";
import { login, setCurrent } from "../Reducer/userSlice";
import { Loader } from "../../Common/Loader";

const MembershipPreviewPage = (props) => {
  const location = useLocation();

  const [slug, setSlug] = useState(null);
  const [participantsData, setParticipantData] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const pathName = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //     const id = location.state && location.state.id;
  //     window.scrollTo(0, 0);
  //     setSlug(id)
  //     getPreviewData(id);
  // }, [location.state]);
  const data = props.data;
  useEffect(() => {
    const id = pathName.id !== undefined && pathName.id;
    window.scrollTo(0, 0);
    setSlug(id);
    getPreviewData(id);
    data && setParticipantData(data);
  }, [pathName.id]);

  const getPreviewData = async (id) => {
    setLoading2(true);
    const res = await Api.getDataFromMembershipId(id);
    if (res.status) {
      console.log(res);
      setParticipantData(res.membership_detail);
      console.log("res", res.membership_detail?.family_member);
      // setParticipantData(stateOptions)
    } else {
      // setParticipants({})
    }
    setLoading2(false);
  };
  const sendEmail = async (id) => {
    setLoading(true);
    const res = await Api.sendEmailtoRegisterMember(id);
    if (res) {
      if (location.state?.isUserLoggedIn === true) {
        paymentGateway(id);
      } else {
        const activateRes = await Api.sendEmailtoActivateMember(id);
        if (activateRes) {
          paymentGateway(id);
          // navigate(`/membership-confirmation/`, { state: { id: id } });
          // navigate(`/membership-confirmation/${id}`);
        }
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const paymentGateway = async (id) => {
    const res = await Api.membershipPaymentGateway(id);
    if (res.status !== false) {
      console.log("paymentGateway res", res.url);
      if (res.url) {
        toast.success("Redirecting to Payment Gateway...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = res.url;
        }, 1000);
      }
    } else {
      setLoading(false);
      if (res.url) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = res.url;
        }, 2000);
      }
    }
  };

  return (
    <>
      {!data && <Header links={props.links} />}
      {loading2 ? (
        <Loader />
      ) : (
        <>
          {loading ? (
            <RedirectPage />
          ) : (
            <div className="register">
              <div className="container">
                <div className="row mx-0">
                  <div className=" col-md-12 pb-4 mx-lg-2">
                    <h1 className="register-title py-4 fs-2 label-font">
                      {data
                        ? "Dharma Ideal Sponsor Membership Details"
                        : "Please Confirm Your Dharma Ideal Sponsor Membership Details"}
                    </h1>

                    <div
                      className="participants-title d-flex justify-content-between align-items-center"
                      id="participants-title-id"
                    >
                      <h4 className="register-title pb-3 label-font col-9">
                        Personnel Information
                      </h4>
                    </div>

                    <div className="row mb-4">
                      <div
                        className={`${
                          data ? "col-12" : "col-12 col-md-6 col-xl-4"
                        } mb-3 form-group`}
                      >
                        <label className="form-label col pl-0">
                          <span>Email</span>
                        </label>
                        <div className="preview-data">
                          {participantsData?.email}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3 form-group">
                        <label className="form-label col pl-0">
                          <span>Full Name</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.full_name}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3 form-group">
                        <label className="form-label col pl-0">
                          <span>Phone</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.phone}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3 form-group">
                        <label className="form-label col pl-0">
                          <span>Sex</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.sex}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3 form-group">
                        <label className="form-label col pl-0">
                          <span>Religion</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.religion}
                        </div>
                      </div>

                      {/* <div className="col-12 col-md-6 col-xl-4 mb-3 form-group"> */}
                      <div
                        className={`${
                          data ? "col-8" : "col-12 col-md-6 col-xl-4"
                        } mb-3 form-group`}
                      >
                        <label className="form-label col pl-0">
                          <span>Membership Duration</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.duration}
                        </div>
                      </div>

                      <div className="col-12 participant-f mb-3">
                        <label className="label-font col pl-0">
                          <span>Address</span>
                        </label>
                        <div className="row mx-0">
                          <div className="col-12 col-md-6 px-0 pe-md-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Country
                            </label>
                            <div className="preview-data text-capitalize">
                              {participantsData?.country_name}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 px-0 mt-3 my-md-0">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              State
                            </label>
                            <div className="preview-data text-capitalize">
                              {participantsData?.state_name
                                ? participantsData?.state_name
                                : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              City
                            </label>
                            <div className="preview-data text-capitalize">
                              {participantsData?.city_new_name
                                ? participantsData?.city_new_name
                                : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Street
                            </label>
                            <div className="preview-data text-capitalize">
                              {participantsData?.street
                                ? participantsData?.street
                                : "-"}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 ps-xl-3 pe-xl-0 mt-3">
                            <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
                              Zip Code
                            </label>
                            <div className="preview-data">
                              {participantsData?.zip_code
                                ? participantsData?.zip_code
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-12 col-md-6 ${
                          data ? "" : "col-xl-4"
                        } participant-f mb-3`}
                      >
                        <label className="form-label col pl-0">
                          <span>Occupation</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.occupation}
                        </div>
                      </div>
                      <div
                        className={`col-12 col-md-6 ${
                          data ? "" : "col-xl-4"
                        } participant-l pe-xl-3`}
                      >
                        <label className="form-label col pl-0">
                          <span>Qualification</span>
                        </label>
                        <div className="preview-data text-capitalize px-3">
                          {participantsData?.qualification}
                        </div>
                      </div>
                      <div
                        className={`col-12 col-md-6 ${
                          data ? "" : "col-xl-4"
                        } participant-l pe-xl-3`}
                      >
                        <label className="form-label col pl-0">
                          <span>Payment Method</span>
                        </label>
                        <div>
                          <img
                            className="payment_logo img-fluid"
                            src={participantsData?.icon_url}
                            alt="Khalti"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      {participantsData?.family_member?.map((elem, index) => (
                        <div
                          key={index}
                          className={`card-div ${
                            data ? "col-12" : "col-lg-6"
                          } col-12`}
                        >
                          <div
                            className="participants-title d-flex justify-content-between align-items-center"
                            id="participants-title-id"
                          >
                            <h4 className="register-title pt-2 label-font col-9">
                              Family Member {index + 1}
                            </h4>
                          </div>
                          <div className="box">
                            <div className="row">
                              <div className="col-12 participant-f from-group mb-3">
                                <label className="form-label col pl-0">
                                  <span>Full Name</span>
                                </label>
                                <div className="preview-data text-capitalize">
                                  {elem.full_name}
                                </div>
                              </div>

                              <div className="col-5 participant-f mb-3">
                                <label className="form-label col pl-0">
                                  <span>Sex</span>
                                </label>
                                <div className="preview-data text-capitalize">
                                  {elem.sex}
                                </div>
                              </div>

                              <div className="col-7 participant-l pl-2 mb-3">
                                <label className="form-label col pl-0">
                                  <span>Religion</span>
                                </label>
                                <div className="preview-data text-capitalize">
                                  {elem.religion}
                                </div>
                              </div>

                              <div className="col-12 col-md-6 participant-f mb-3">
                                <label className="form-label col pl-0">
                                  <span>Phone</span>
                                </label>
                                <div className="preview-data">{elem.phone}</div>
                              </div>

                              <div className="col-12 col-md-6 participant-l pl-2 mb-3">
                                <label className="form-label col pl-0">
                                  <span>Occupation</span>
                                </label>
                                <div className="preview-data">
                                  {elem.occupation}
                                </div>
                              </div>

                              <div className="col-12 col-md-8 participant-f mb-3">
                                <label className="label-font col pl-0">
                                  <span>Family Relation</span>
                                </label>
                                <div className="preview-data col-6 col-md-7 text-capitalize">
                                  {elem.family_relation}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {!data && (
                      <>
                        <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                          <Button
                            as={Link}
                            to={`/edit-membership/${slug}`}
                            // state={{ id: slug }}
                            className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1"
                            type="button"
                          >
                            Edit
                          </Button>

                          <Button
                            className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1"
                            type="button"
                            onClick={() => sendEmail(slug)}
                          >
                            Confirm
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {!data && (
        <>
          <Footer
            links={props.links}
            contacts={props.contacts}
            footerDescription={props.footerDescription}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default MembershipPreviewPage;
