import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   details: {},
//   currentUser: null,
//   isLoggedIn: false,
// };
const initialState = {
  details: {},
  currentUser: null,
  isLoggedIn: false,
  donor: {},
  eventRegistration: {},
  membership: {},
  sponsorship: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.details = action.payload.user_detail;
      state.donor = action.payload.donor;
      state.eventRegistration = action.payload.event_registration;
      state.membership = action.payload.membership;
      state.sponsorship = action.payload.sponsorship;
    },
    logout: (state) => {
      localStorage.clear();
      return initialState;
    },
    setCurrent: (state, action) => {
      state.isLoggedIn = true;
      state.details = action.payload.user_detail;
      state.donor = action.payload.donor;
      state.eventRegistration = action.payload.event_registration;
      state.membership = action.payload.membership;
      state.sponsorship = action.payload.sponsorship;
    },
  },
});

export const { login, logout, setCurrent } = userSlice.actions;

export const userReducer = userSlice.reducer;
