import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import { Api, Images } from "../../Constants";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { IoLogoTwitter } from "react-icons/io";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { FaUser } from "react-icons/fa6";

import "./Header.css";
import { Button, NavItem } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirect } from "../../Pages/Reducer/routesSlice";
import { logout } from "../../Pages/Reducer/userSlice";

const Header = (props) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login/");
    dispatch(clearRedirect());
    dispatch(logout());
    window.location.reload();
  };

  return (
    <>
      <Container>
        <div className="row mx-2 mx-xl-0">
          <div className="col col-md-12 d-flex justify-content-sm-end justify-content-end flex-row  align-items-xl-center my-1 px-0 py-1">
            {/* <Link to='https://forms.gle/vrQjzptkWFncuuzo8' target="_blank" className="text-uppercase mx-2 h1-links d-none d-lg-block" title="Register Now" >Register Now</Link> */}
            {/* <Link to='/donate-now' className="text-uppercase mx-2 h1-links d-none d-lg-block" title="Register Now" >Donate Now</Link> */}
            <NavDropdown
              title="Dharma Ideal Sponsor Member"
              id="basic-nav-dropdown"
              className="text-capitalize mx-2 h1-links d-none d-lg-block"
            >
              <NavDropdown.Item
                as={Link}
                to="/about-dharma-ideal-sponsor-member"
              >
                About
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/member">
                Be a Dharma Ideal Sponsor Member
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="International Events"
              id="basic-nav-dropdown"
              className="text-capitalize mx-2 h1-links d-none d-lg-block"
            >
              <NavDropdown.Item as={Link} to="/international-events">
                About
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/register">
                Register Now
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/sponsor">
                Be an International Event Sponsor
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Link to='/register' className="text-capitalize mx-2 h1-links d-none d-lg-block" title="Register Now" >Register Now</Link> */}
            {/* <Link to='/contact-us' className="text-capitalize mx-2 h1-links d-none d-lg-block" title="Contact Us" >Contact Us</Link> */}
            <Link to="/donate-now" className="mx-2">
              <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                Donate Now
                {/* {showMore ? 'Show Less' : 'Show More'} */}
              </button>
            </Link>
            {user.isLoggedIn ? (
              <>
                <NavDropdown
                  title={
                    <span>
                      <FaUser className="me-1" />
                      {user.details.full_name}
                    </span>
                  }
                  id="profile-dropdown "
                  className="text-capitalize mx-2 h1-links d-none d-lg-block"
                >
                  <NavDropdown.Item as={Link} to="/profile">
                    View Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-uppercase mx-2 profile-icon"
                  title=""
                >
                  <FaUser />
                </Link>
              </>
            )}
            {/* <a href={props.links.facebook} target="_blank" title={props.links.facebook} className="h-links"><ImFacebook className="h-icon mx-3 my-3" /></a>
                        <a href={props.links.linkedin} target="_blank" title={props.links.linkedin} className="h-links"><FaLinkedinIn className="h-icon mx-3 my-3" /></a>
                        <a href={`mailto:${props.links.mail}`} target="_blank" title={props.links.mail} className="h-links"><MdMailOutline className="h-icon mx-3 my-3" /></a>
                        <a href={props.links.youtube} target="_blank" title={props.links.youtube} className="h-links"><FaYoutube className="h-icon mx-3 my-3" /></a> */}
          </div>
        </div>
      </Container>

      <Navbar expand="xl" className="header-nav">
        {/* <Container className="pe-0"> */}
        <div className="container">
          <Navbar.Brand href="/" title="Dharma Ideal Campaign">
            <img
              src={Images.logo2}
              className="h-logo img-fluid ms-2 ms-lg-0 mt-2 mt-md-0"
              alt="Dharma Ideal Campaign"
            />
            {/* <span className="h-name">Dharma Ideal Campaign</span> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3  ">
            <GiHamburgerMenu color="#fff" className="hamburger" />{" "}
            <ImCross
              color="#fff"
              className="cross"
              style={{ height: "0.7rem" }}
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 d-flex align-items-start align-items-xl-center">
              {/* <HashLink to='/#dharma-ideal-campaign' className="text-uppercase mx-4 mx-lg-2 my-2 my-lg-0 nav-custom-edits" title="Dharma Ideal Campaign" >Dharma Ideal Campaign</HashLink>
                            <HashLink to='/#latest-news' className="text-uppercase mx-2 nav-custom-edits mx-4 mx-lg-2 my-2 my-lg-0 " title="Latest News" >Latest News</HashLink>
                            <HashLink to='/#dharma-projects' className="text-uppercase mx-2  nav-custom-edits mx-4 mx-lg-2 my-2 my-lg-0 " title="Dharma Projects">Dharma Projects</HashLink>
                            <HashLink to='/#schedule' className="text-uppercase mx-2  nav-custom-edits mx-4 mx-lg-2 my-2 my-lg-0 " title="Schedule">Schedule</HashLink>
                            <HashLink to='/#international-events' className="text-uppercase mx-2  nav-custom-edits mx-4 mx-lg-2 my-2 my-lg-0 " title="International Events">International Events</HashLink> */}

              <NavDropdown
                title="About"
                className="text-uppercase mx-4 mx-xl-2 mx-xl-0 mt-4 my-xl-0 nav-custom-edits"
              >
                <NavDropdown.Item
                  as={Link}
                  smooth
                  to="/about-dharma-ideal-campaign"
                >
                  Dharma Ideal Campaign
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/about-venerable-khem-sonam-rinpoche"
                >
                  Ven. Khem Sonam Rinpoche
                </NavDropdown.Item>
              </NavDropdown>
              {/* <HashLink to='/#dharma-ideal-campaign' className="text-uppercase mx-4 mx-xl-2 my-2 my-xl-0 nav-custom-edits" title="Dharma Ideal Campaign" >Dharma Ideal Campaign</HashLink> */}
              <Link
                to="/latest-news"
                className="text-uppercase mx-2 nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                title="Latest News"
              >
                Latest News
              </Link>
              <Link
                to="/dharma-projects"
                className="text-uppercase mx-2  nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                title="Dharma Projects"
              >
                Dharma Projects
              </Link>
              <Link
                to="/schedule"
                className="text-uppercase mx-2  nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                title="Schedule"
              >
                Schedule
              </Link>
              <Link
                to="/contact-us"
                className="text-uppercase mx-2  nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                title="Contact Us"
              >
                Contact Us
              </Link>
              <NavDropdown
                title="International Events"
                id="basic-nav-dropdown"
                className="text-uppercase mx-2 nav-custom-edits_d d-lg-none mx-4 mx-lg-2 mb-lg-0 "
              >
                <NavDropdown.Item as={Link} to="/international-events">
                  About
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/register">
                  Register Now
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/sponsor">
                  Be an International Event Sponsor
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Dharma Ideal Sponsor Member"
                id="basic-nav-dropdown"
                className="text-uppercase mx-2 nav-custom-edits_d d-lg-none mx-4 mx-lg-2 mb-2 mb-lg-0 "
              >
                <NavDropdown.Item
                  as={Link}
                  to="/about-dharma-ideal-sponsor-member"
                >
                  About
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/member">
                  Be a Dharma Ideal Sponsor Member
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Link to='/register' className="text-uppercase mx-2 nav-custom-edits d-lg-none mx-4 mx-xl-2 my-2 my-xl-0 " title="Dharma Ideal Campaign" >Register Now</Link> */}
              {/* <Link to='/contact-us' className="text-uppercase mx-2 nav-custom-edits d-lg-none mx-4 mx-xl-2 my-2 my-xl-0 " title="Dharma Ideal Campaign" >Contact Us</Link> */}
              {/* <Nav.Item className="h-icon ms-2 me-4 my-3 d-none d-lg-block"> <FaSearch /> </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </div>

        {/* </Container> */}
      </Navbar>
    </>
  );
};

export default Header;
