import React, { useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { HelperFunction, Images } from "../../Constants";

import "./Banner.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const Banner = (props) => {
  const fixImageContainerHeight = (id) => {
    const imageContainer = document.getElementById(id);
    imageContainer.style.height = "auto";
  };

  return (
    <>
      <Carousel
        className="banner"
        nextIcon={
          props.bannerPictures.length > 1 && (
            <span className="carousel-control-next-icon" title="Next" />
          )
        }
        prevIcon={
          props.bannerPictures.length > 1 && (
            <span className="carousel-control-prev-icon" title="Previous" />
          )
        }
      >
        {props.bannerPictures.map((elem, index) => (
          <Carousel.Item key={index} interval={3000}>
            {elem.main_image ? (
              <div
                id={`carousel-img-placeholder-${index}`}
                className="carousel-placeholder"
              >
                <picture>
                  <source
                    media="(min-width:2560px)"
                    srcSet={`${HelperFunction.FILE_URL}/main_image/thumbnail/uhd/${elem.main_image}`}
                  />
                  <source
                    media="(min-width:1921px)"
                    srcSet={`${HelperFunction.FILE_URL}/main_image/thumbnail/qhd/${elem.main_image}`}
                  />
                  <source
                    media="(min-width:1360px)"
                    srcSet={`${HelperFunction.FILE_URL}/main_image/thumbnail/fhd/${elem.main_image}`}
                  />
                  <source
                    media="(min-width:992px)"
                    srcSet={`${HelperFunction.FILE_URL}/main_image/thumbnail/hd/${elem.main_image}`}
                  />
                  <source
                    media="(min-width:759px)"
                    srcSet={`${HelperFunction.FILE_URL}/main_image/thumbnail/lhd/${elem.main_image}`}
                  />
                  <source
                    media="(max-width:758px)"
                    srcSet={`${HelperFunction.FILE_URL}/mobile_image/${elem.mobile_image}`}
                  />
                  {/* <source media="(max-width:574px)" srcSet={`${HelperFunction.FILE_URL}/mobile_image/${elem.mobile_image}`} /> */}
                  <img
                    onLoad={() =>
                      fixImageContainerHeight(
                        `carousel-img-placeholder-${index}`
                      )
                    }
                    src={`${HelperFunction.FILE_URL}/main_image/${elem.main_image}`}
                    alt="dharma"
                    className="img-fluid w-100"
                  />
                  {/* <div className="">Hello</div> */}
                </picture>
                {elem.button_required == "yes" ? (
                  <>
                    <Link to={`/${elem.url}`}>
                      <Button variant="warning" className="button-register">
                        Register Now
                      </Button>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default Banner;
