import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../Common";
import { useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from "react-bootstrap/Button";

import "./Member.css";
import { FormSelect } from "react-bootstrap";
import { Api, Images } from "../../Constants";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ParticipantCountry from "../Register/ParticipantCountry";
import { useSelector, useDispatch } from "react-redux";
import { updateRoute } from "../Reducer/routesSlice";
import { Loader } from "../../Common/Loader";

const religionOptions = [
  {
    id: "1",
    value: "buddhism",
    label: "Buddhism",
  },
  {
    id: "2",
    value: "hinduism",
    label: "Hinduism",
  },
  {
    id: "3",
    value: "islam",
    label: "Islam",
  },
  {
    id: "4",
    value: "christainity",
    label: "Christainity",
  },
  {
    id: "5",
    value: "sikhism",
    label: "Sikhism",
  },
  {
    id: "6",
    value: "others",
    label: "Others",
  },
  {
    id: "7",
    value: "prefer not to say",
    label: "Prefer Not to Say",
  },
];

const genderOption = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Others",
  },
];

const familyRelationOptions = [
  {
    id: "1",
    value: "spouse",
    label: "Spouse",
  },
  {
    id: "2",
    value: "son",
    label: "Son",
  },
  {
    id: "3",
    value: "daughter",
    label: "Daughter",
  },
];

const Member = (props) => {
  // react redux useSelector hook to access the state
  const user = useSelector((state) => state.user);

  const userDetails = user && user.isLoggedIn ? user.details : null;

  const route = useSelector((state) => state.route);

  // react redux useDispatch
  const dispatch = useDispatch();

  const [participants, setParticipants] = useState(1);
  const [familyMembers, setFamilyMembers] = useState([{ id: 1 }]);

  const [cardDivHeight, setCardDivHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  const [religion, setReligion] = useState(religionOptions);
  const [gender, setGenderOption] = useState(genderOption);
  // const [country, setCountry] = useState(countryOptions);
  const [relation, setRelation] = useState(familyRelationOptions);
  const [membership, setMembership] = useState([]);
  const [selectedGender, setSelectedGender] = useState(gender[1].value);
  const [selectedReligion, setSelectedReligion] = useState();
  const [membershipDuration, setSelectedMembershipDuration] = useState();
  const [familyRelation, setFamilyRelation] = useState();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [exampleIndex, setSelectedIndex] = useState(0);
  const [paymentMethodData, setPaymentMethodData] = useState([]);

  const [agree, setAgree] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const [applicationId, setApplicationId] = useState();
  const [slug, setSlug] = useState(null);
  const [paymentType, setPaymentType] = useState();
  const [loading, setLoading] = useState(false);
  const {
    clearErrors,
    getValues,
    setValue,
    register,
    handleSubmit,
    unregister,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const pathName = useParams();
  const location = useLocation();

  const animatedComponents = makeAnimated();

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     getMembershipType();
  //     if (location.state) {
  //         setSlug(location.state.id)
  //         getParticipantsDetail(location.state.id);
  //     }

  //     // Get the height of the card-div
  //     const cardDivElement = document.querySelector('.box');
  //     const height = cardDivElement ? cardDivElement.offsetHeight : 0;

  //     // Set the height of addBtn-div to match card-div
  //     setCardDivHeight(height);
  // }, [location.state, location.state === null && errors]);

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      console.log("pathname.slug", pathName.id);
      await getMembershipType();
      if (pathName.id !== undefined) {
        setSlug(pathName.id);
        await getParticipantsDetail(pathName.id);
      }

      // Get the height of the card-div
      const cardDivElement = document.querySelector(".box");
      const height = cardDivElement ? cardDivElement.offsetHeight : 0;

      // Set the height of addBtn-div to match card-div
      setCardDivHeight(height);
    };

    fetchData();
  }, [pathName.id, errors]);

  const getParticipantsDetail = async (id) => {
    setLoading(true);
    const res = await Api.getDataFromMembershipId(id);
    if (res.status) {
      setParticipants(res.membership_detail);

      setValue(`email`, res.membership_detail.email);
      setValue(`full_name`, res.membership_detail.full_name);
      setValue(`phone`, res.membership_detail.phone);
      setValue(`sex`, res.membership_detail.sex);
      setValue(`religion`, res.membership_detail.religion);
      setValue(
        `membership_duration_id`,
        res.membership_detail.membership_duration_id
      );
      setValue(`country_id[${exampleIndex}]`, res.membership_detail.country_id);
      setValue(`state_id[${exampleIndex}]`, res.membership_detail.state_id);
      setValue(
        `city_new_name[${exampleIndex}]`,
        res.membership_detail.city_new_name
      );
      setValue(`street[${exampleIndex}]`, res.membership_detail.street);
      setValue(`zip_code[${exampleIndex}]`, res.membership_detail.zip_code);
      setValue(`occupation`, res.membership_detail.occupation);
      setValue(`qualification`, res.membership_detail.qualification);

      setSelectedCountry(() => ({
        [exampleIndex]: res.membership_detail.country_id,
      }));
      setSelectedState(() => ({
        [exampleIndex]: res.membership_detail.state_id,
      }));
      setFamilyMembers([]);
      res.membership_detail.family_member.forEach((elem, index) => {
        setFamilyMembers((prev) => [...prev, { id: index }]);
        setValue(`family_member[${index}].uuid`, elem.id);
        setValue(`family_member[${index}].full_name`, elem.full_name);
        setValue(`family_member[${index}].sex`, elem.sex);
        setValue(`family_member[${index}].religion`, elem.religion);
        setValue(`family_member[${index}].phone`, elem.phone);
        setValue(`family_member[${index}].occupation`, elem.occupation);
        setValue(
          `family_member[${index}].family_relation`,
          elem.family_relation
        );
      });

      // getPaymentMethod(res.membership_detail.payment_id);
      setPaymentType(res.membership_detail.payment_id);
      setValue(`payment_id`, res.membership_detail.payment_id);
    } else {
    }
    setLoading(false);
  };

  const getPaymentMethod = async (id) => {
    const res = await Api.getPaymentMethodByCountry(id);

    if (res) {
      console.log("res", res);
      setPaymentMethodData(res.payment_detail);
      if (location.state?.id) {
        setPaymentType(participants.payment_id);
      } else {
        setPaymentType(res.payment_detail[0].id);
      }
    } else {
      setPaymentMethodData([]);
      toast.error("No payment modes were found for this country.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePaymentChange = (id) => {
    console.log("value", id);
    setValue(`payment_id`, id);
    console.log("value", getValues(`payment_id`));
  };

  const getMembershipType = async () => {
    const res = await Api.getMembershipType();

    if (res.status !== false) {
      setMembership(res.membership_duration);
    } else {
      console.log("status", res);
    }
  };

  const setCardHeight = () => {
    // Get the height of the card-div
    const cardDivElement = document.querySelectorAll(".box");
    const lastAppendedBox = cardDivElement[cardDivElement.length - 1];

    const height = lastAppendedBox ? lastAppendedBox.offsetHeight - 2 : 0;
    // Set the height of addBtn-div to match card-div
    setCardDivHeight(height);

    const cardDivs = document.querySelectorAll(".box");

    let newMaxHeight = 0;

    cardDivs.forEach((cardDiv) => {
      const height = cardDiv.offsetHeight; // Include padding and border
      console.log("height", height);
      newMaxHeight = Math.max(newMaxHeight, height);
      console.log("newMaxHeight", newMaxHeight);
    });

    setMaxHeight(newMaxHeight);
  };

  const handleAddParticipant = () => {
    setFamilyMembers((prevParticipants) => {
      const newParticipants = [...prevParticipants, { id: Date.now() }];
      return newParticipants;
    });
    setCardHeight();
  };

  const handleDeleteCard = (index) => {
    setFamilyMembers((prevParticipants) => {
      const updatedParticipants = [...prevParticipants];
      updatedParticipants.splice(index, 1);
      setFamilyMembers(updatedParticipants);
      return updatedParticipants;
    });

    // set value for the participants after the participant with the index has been removed
    var totalParticipants = familyMembers.length;
    for (let i = index; i < totalParticipants; i++) {
      console.log(index, i);
      console.log("totalParticipants", totalParticipants);
      setValue(
        `family_member[${i}].full_name`,
        getValues(`family_member[${i + 1}].full_name`)
      );
      setValue(
        `family_member[${i}].sex`,
        getValues(`family_member[${i + 1}].sex`)
      );
      setValue(
        `family_member[${i}].religion`,
        getValues(`family_member[${i + 1}].religion`)
      );
      setValue(
        `family_member[${i}].phone`,
        getValues(`family_member[${i + 1}].phone`)
      );
      setValue(
        `family_member[${i}].occupation`,
        getValues(`family_member[${i + 1}].occupation`)
      );
      setValue(
        `family_member[${i}].family_relation`,
        getValues(`family_member[${i + 1}].family_relation`)
      );
    }

    // Clear values for the last participant
    unregister([
      `family_member[${totalParticipants - 1}].full_name`,
      `family_member[${totalParticipants - 1}].sex`,
      `family_member[${totalParticipants - 1}].religion`,
      `family_member[${totalParticipants - 1}].phone`,
      `family_member[${totalParticipants - 1}].occupation`,
      `family_member[${totalParticipants - 1}].family_relation`,
    ]);

    setCardHeight();

    toast.success("Family Member Deleted Successfully !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // callback function to store the country list
  const handleCountryChange = (index, id) => {
    // Update a participant country with the given index
    setSelectedCountry((prev) => ({ ...prev, [index]: id }));
    getPaymentMethod(id);
  };

  // callback function to store the state list
  const handleStateChange = (index, id) => {
    // Update a participant's state with the given index
    setSelectedState((prev) => ({ ...prev, [index]: id }));
  };

  // callback function to store the city list
  const handleCityChange = (index, id) => {
    // Update a participant's city with the given index
    setSelectedCity((prev) => ({ ...prev, [index]: id }));
  };

  const checkEmail = async (e) => {
    const inputValue = e.target.value;
    const res = await Api.checkEmail(inputValue);

    if (res.status !== false) {
      console.log("res.data", res.data);
      toast.success(
        "Participant with this email already exists! Please login.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      // Dispatch the updateRoute action before navigating
      dispatch(updateRoute(window.location.pathname));

      // navigate to the login route with state
      setTimeout(() => {
        navigate(`/login`, { state: { email: inputValue } });
      }, 2000);
    } else {
      console.log("status", res);
    }
  };

  const handleAgreeCheck = () => {
    setChecked(!checked);
    if (checked) {
      setAgree(false);
    } else {
      setAgree(true);
    }
  };

  const onSubmit = (data) => handleFormSubmit(data);

  const handleFormSubmit = async (data) => {
    console.log("prev data", data);
    const formattedData = {
      city_new_name: data.city_new_name?.[0],
      country_id: data.country_id?.[0],
      state_id: data.state_id?.[0],
      street: data.street?.[0],
      zip_code: data.zip_code?.[0],
    };

    data.city_new_name = formattedData?.city_new_name;
    data.country_id = formattedData?.country_id;
    data.state_id = formattedData?.state_id;
    data.street = formattedData?.street;
    data.zip_code = formattedData?.zip_code;

    console.log("formattedData", formattedData);
    console.log("data", data);
    const postData = {
      ...data,
    };

    let res;

    if (!slug) {
      res = await Api.postMember(postData);
    } else {
      res = await Api.updateMember(postData, slug);
    }

    if (res.status) {
      setApplicationId(res.user_id);
      // navigate(`/preview-membership-page`, { state: { id: res.user_id } });
      navigate(`/preview-membership-page/${res.user_id}`);
    } else {
      if (res.message) {
        console.log("res.message", res.message);

        const error = res.message;
        if (typeof res.message === "object") {
          // sets error message for the fields using the setError of react-hook-form
          Object.keys(error).forEach((field) => {
            const errorMessage = error[field];

            // Check if the error message exists and has a length
            if (errorMessage && errorMessage.length > 0) {
              setError(field, {
                type: "manual",
                message: errorMessage, // Assuming errorMessage is a string or an array
              });
            }
          });
        } else {
          // If res.message is not an object, display a general error using toast
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }

      // call setCardHeight to set the height of the addBtn-div if the error occurs
      setTimeout(() => {
        setCardHeight();
      }, 30);
    }
  };

  return (
    <div>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="register">
            <div className="container ">
              <div className="row mx-0">
                <div className=" col-md-12 pb-4 mx-lg-2">
                  <h1 className="register-title py-4 fs-2 label-font">
                    Dharma Ideal Sponsor Membership Form
                  </h1>

                  <div
                    className="participants-title d-flex justify-content-between align-items-center"
                    id="participants-title-id"
                  >
                    <h4 className="register-title pb-3 label-font col-9">
                      Personnel Information
                    </h4>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6 col-xl-4 mb-3 form-group">
                        <label className="form-label col pl-0">
                          <span>Email *</span>
                        </label>
                        {userDetails?.email ? (
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Email"
                            value={user.details.email}
                            disabled
                          />
                        ) : (
                          <>
                            {pathName.id !== undefined ? (
                              <>
                                <input
                                  {...register(`email`, {
                                    required: "Valid email is required",
                                    pattern: {
                                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                      message: "Valid email is required",
                                    },
                                  })}
                                  // onBlur={checkEmail}
                                  type="text"
                                  className={`form-control ${
                                    errors?.email && "border-danger"
                                  }`}
                                  placeholder="Email"
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  {...register(`email`, {
                                    required: "Valid email is required",
                                    pattern: {
                                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                      message: "Valid email is required",
                                    },
                                  })}
                                  onBlur={checkEmail}
                                  type="text"
                                  className={`form-control ${
                                    errors?.email && "border-danger"
                                  }`}
                                  placeholder="Email"
                                />
                              </>
                            )}
                            {errors?.email && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.email.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3">
                        <label className="form-label col pl-0">
                          <span>Full Name *</span>
                        </label>
                        {userDetails?.full_name ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              value={user.details.full_name}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <input
                              {...register(`full_name`, {
                                required: "Full Name is required",
                                pattern: {
                                  value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                                  message: "Invalid full name",
                                },
                              })}
                              type="text"
                              className={`form-control ${
                                errors?.full_name && "border-danger"
                              }`}
                              placeholder="Full Name"
                            />
                            {errors?.full_name && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.full_name.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-3">
                        <label className="required form-label col pl-0">
                          <span>Phone *</span>
                        </label>
                        {userDetails?.phone ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              value={user.details.phone}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <input
                              {...register(`phone`, {
                                required: "Valid phone number is required",
                                pattern: {
                                  // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                  value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                  message: "Valid phone number is required",
                                },
                              })}
                              type="text"
                              className={`form-control ${
                                errors?.phone && "border-danger"
                              }`}
                              placeholder="Phone Number"
                            />
                            {errors?.phone && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.phone.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </>
                        )}
                      </div>

                      {/* <div className="row mb-4"> */}
                      <div className="col-5 col-md-6 col-xl-4 mb-3">
                        <label className="required form-label col pl-0">
                          <span>Sex *</span>
                        </label>
                        {userDetails?.sex ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              value={user.details.sex}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <select
                              {...register(`sex`, {
                                required: "Sex is required.",
                              })}
                              className={`form-control ${
                                errors?.sex && "border-danger"
                              }`}
                              defaultValue={selectedGender}
                              onChange={(e) =>
                                setSelectedGender(e.target.value)
                              }
                            >
                              {/* <option value="">Choose option</option> */}
                              {gender.map((elem) => (
                                <option key={elem.value} value={elem.value}>
                                  {elem.label}
                                </option>
                              ))}
                            </select>
                            {errors?.sex && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.sex.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-7 col-md-6 col-xl-4 pl-2 mb-3">
                        <label className="form-label col pl-0">
                          <span>Religion*</span>
                        </label>
                        {userDetails?.religion ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              value={user.details.religion}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <select
                              {...register(`religion]`, {
                                required: "Religion is required.",
                              })}
                              className={`form-control ${
                                errors?.religion && "border-danger"
                              }`}
                              // value={selectedReligion}
                              onChange={(e) =>
                                setSelectedReligion(e.target.value)
                              }
                            >
                              <option value="" hidden>
                                Choose option
                              </option>
                              {religion.map((elem) => (
                                <option key={elem.value} value={elem.value}>
                                  {elem.label}
                                </option>
                              ))}
                            </select>
                            {errors?.religion && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: errors.religion.message,
                                }}
                                className="text-danger"
                              />
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 form-group mb-3">
                        <label className="form-label col pl-0">
                          <span>Membership Duration*</span>
                        </label>

                        <select
                          {...register(`membership_duration_id`, {
                            required: "Membership Duration is required.",
                          })}
                          className={`form-control ${
                            errors?.membership_duration_id && "border-danger"
                          }`}
                          onChange={(e) =>
                            setSelectedMembershipDuration(e.target.value)
                          }
                        >
                          <option value="" hidden>
                            Choose option
                          </option>
                          {membership?.map((elem) => (
                            <option key={elem.id} value={elem.id}>
                              {elem.duration} ({elem.currency} {elem.amount})
                            </option>
                          ))}
                        </select>
                        {errors?.membership_duration_id && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.membership_duration_id?.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>
                      {/* </div> */}

                      <div className="col-12 participant-f mb-4">
                        <div className="form-group">
                          <label className="form-label col pl-0">
                            <span>Address</span>
                          </label>
                          {!location.state?.id ? (
                            <>
                              <ParticipantCountry
                                key={exampleIndex}
                                index={exampleIndex}
                                onCountryChange={handleCountryChange}
                                onStateChange={handleStateChange}
                                onCityChange={handleCityChange}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                selectedCountryParent={
                                  selectedCountry[exampleIndex]
                                }
                                selectedStateParent={
                                  selectedState[exampleIndex]
                                }
                                selectedCityParent={selectedCity[exampleIndex]}
                              />
                            </>
                          ) : (
                            <>
                              {selectedCountry && (
                                <>
                                  <ParticipantCountry
                                    key={exampleIndex}
                                    index={exampleIndex}
                                    onCountryChange={handleCountryChange}
                                    onStateChange={handleStateChange}
                                    onCityChange={handleCityChange}
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    selectedCountryParent={
                                      selectedCountry[exampleIndex]
                                    }
                                    selectedStateParent={
                                      selectedState
                                        ? selectedState[exampleIndex]
                                        : undefined
                                    }
                                    selectedCityParent={
                                      selectedCity[exampleIndex]
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 participant-f mb-3">
                        <div className="form-group">
                          <label className="form-label col pl-0">
                            <span>Occupation*</span>
                          </label>
                          <input
                            {...register(`occupation`, {
                              required: "Occupation is required",
                            })}
                            type="text"
                            className={`form-control ${
                              errors?.occupation && "border-danger"
                            }`}
                            placeholder="Occupation"
                          />
                          {errors?.occupation && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.occupation.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 participant-l pe-xl-3">
                        <div className="form-group ">
                          <label className="form-label col pl-0">
                            <span>Qualification *</span>
                          </label>
                          <input
                            {...register(`qualification`, {
                              required: "Qualification is required",
                            })}
                            type="text"
                            className={`form-control ${
                              errors?.qualification && "border-danger"
                            }`}
                            placeholder="Qualification"
                          />
                          {errors?.qualification && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.qualification.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 participant-l pe-xl-3">
                        <div className="form-group ">
                          <label className="form-label col pl-0">
                            <span>Payment Method *</span>
                          </label>
                          <div
                            className="form-radio-group d-flex flex-row"
                            id="form-radio-group"
                          >
                            {paymentMethodData?.map((elem, index) => (
                              <label
                                key={elem.id}
                                className="form-radio-item payment-div"
                                data-toggle="modal"
                                data-target="#paymentGatewayModal"
                                title={elem.payment_method}
                              >
                                <input
                                  type="radio"
                                  value={elem.id}
                                  id={elem.id}
                                  name="payment_id"
                                  checked={paymentType === elem.id}
                                  {...register("payment_id", {
                                    required: "Donation Detail is required.",
                                  })}
                                  onChange={() => {
                                    setPaymentType(elem.id);
                                    handlePaymentChange(elem.id);
                                  }}
                                />
                                <img
                                  className="payment_logo img-fluid"
                                  src={elem.icon_url}
                                  alt={elem.payment_method}
                                />
                              </label>
                            ))}
                          </div>
                          {errors?.payment_id && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: errors.payment_id.message,
                              }}
                              className="text-danger"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      {familyMembers.map((elem, index) => (
                        <div
                          key={index}
                          className="card-div col-12 col-lg-6"
                          id={elem.id}
                        >
                          <div
                            className="participants-title d-flex justify-content-between align-items-center"
                            id="participants-title-id"
                          >
                            <h4 className="register-title pt-2 label-font col-9">
                              Family Member {index + 1}
                            </h4>
                            {!slug && (
                              <div
                                className={`deleteParticipants_div col text-end ${
                                  familyMembers.length > 1 && index !== 0
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                <button
                                  className="btn btn-danger delete_participants"
                                  type="button"
                                  onClick={() => handleDeleteCard(index)}
                                >
                                  x
                                </button>
                              </div>
                            )}
                          </div>
                          <div
                            className="box"
                            style={{ minHeight: `${maxHeight}px` }}
                          >
                            <div className="row">
                              <div className="col-12 participant-f form-group mb-3">
                                <label className="form-label col pl-0">
                                  <span>Full Name *</span>
                                </label>
                                <input
                                  {...register(
                                    `family_member[${index}].full_name`,
                                    {
                                      required: "Full Name is required",
                                      pattern: {
                                        value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                                        message: "Invalid full name",
                                      },
                                    }
                                  )}
                                  type="text"
                                  className={`form-control ${
                                    errors?.family_member?.[index]?.full_name &&
                                    "border-danger"
                                  }`}
                                  placeholder="Full Name"
                                />
                                {errors?.family_member?.[index]?.full_name && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        errors.family_member?.[index]?.full_name
                                          .message,
                                    }}
                                    className="text-danger"
                                  />
                                )}
                              </div>

                              {slug && (
                                <input
                                  {...register(
                                    `family_member[${index}].uuid`,
                                    {}
                                  )}
                                  hidden
                                  type="text"
                                />
                              )}

                              <div className="col-5 participant-f mb-3">
                                <div className="form-group">
                                  <label className="required form-label col pl-0">
                                    <span>Sex *</span>
                                  </label>
                                  <select
                                    {...register(
                                      `family_member[${index}].sex`,
                                      {
                                        required: "Sex is required.",
                                      }
                                    )}
                                    className={`form-control ${
                                      errors?.family_member?.[index]?.sex &&
                                      "border-danger"
                                    }`}
                                    defaultValue={selectedGender}
                                    onChange={(e) =>
                                      setSelectedGender(e.target.value)
                                    }
                                  >
                                    {/* <option value="">Choose option</option> */}
                                    {gender.map((elem) => (
                                      <option
                                        key={elem.value}
                                        value={elem.value}
                                      >
                                        {elem.label}
                                      </option>
                                    ))}
                                  </select>
                                  {errors?.family_member?.[index]?.sex && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          errors.family_member?.[index]?.sex
                                            .message,
                                      }}
                                      className="text-danger"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-7 participant-l pl-2 mb-3">
                                <label className="form-label col pl-0">
                                  <span>Religion*</span>
                                </label>
                                <select
                                  {...register(
                                    `family_member[${index}].religion`,
                                    {
                                      required: "Religion is required.",
                                    }
                                  )}
                                  className={`form-control ${
                                    errors?.family_member?.[index]?.religion &&
                                    "border-danger"
                                  }`}
                                  // defaultValue={selectedGender}
                                  onChange={(e) =>
                                    setSelectedReligion(e.target.value)
                                  }
                                >
                                  <option value="" hidden>
                                    Choose option
                                  </option>
                                  {religion.map((elem) => (
                                    <option key={elem.value} value={elem.value}>
                                      {elem.label}
                                    </option>
                                  ))}
                                </select>
                                {errors?.family_member?.[index]?.religion && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        errors.family_member?.[index]?.religion
                                          .message,
                                    }}
                                    className="text-danger"
                                  />
                                )}
                              </div>

                              <div className="col-12 col-md-6 participant-f mb-3">
                                <div className="form-group">
                                  <label className="required form-label col pl-0">
                                    <span>Phone *</span>
                                  </label>
                                  <input
                                    {...register(
                                      `family_member[${index}].phone`,
                                      {
                                        required:
                                          "Valid phone number is required",
                                        pattern: {
                                          // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                          value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                          message:
                                            "Valid phone number is required",
                                        },
                                      }
                                    )}
                                    type="text"
                                    className={`form-control ${
                                      errors?.family_member?.[index]?.phone &&
                                      "border-danger"
                                    }`}
                                    placeholder="Phone Number"
                                  />
                                  {errors?.family_member?.[index]?.phone && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          errors.family_member?.[index]?.phone
                                            .message,
                                      }}
                                      className="text-danger"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-12 col-md-6 participant-l pl-2 mb-3">
                                <label className="form-label col pl-0">
                                  <span>Occupation*</span>
                                </label>
                                <input
                                  {...register(
                                    `family_member[${index}].occupation`,
                                    {
                                      required: "Occupation is required",
                                    }
                                  )}
                                  type="text"
                                  className={`form-control ${
                                    errors?.family_member?.[index]
                                      ?.occupation && "border-danger"
                                  }`}
                                  placeholder="Occupation"
                                />
                                {errors?.family_member?.[index]?.occupation && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        errors.family_member?.[index]
                                          ?.occupation.message,
                                    }}
                                    className="text-danger"
                                  />
                                )}
                              </div>

                              <div className="col-12 col-md-8 participant-f mb-3">
                                <div className="form-group ">
                                  <label className="form-label pl-0">
                                    <span>Family Relation *</span>
                                  </label>
                                  <div className="col-6 col-md-6 pe-md-2">
                                    <select
                                      {...register(
                                        `family_member[${index}].family_relation`
                                      )}
                                      className={`form-control ${
                                        errors?.family_member?.[index]
                                          ?.family_relation && "border-danger"
                                      }`}
                                      defaultValue={
                                        relation.find(
                                          (rel) => rel.value === "spouse"
                                        )?.value || ""
                                      }
                                      onChange={(e) => {
                                        setFamilyRelation(e.target.value);
                                      }}
                                    >
                                      {relation.map((elem, indexelem) => (
                                        <option
                                          key={elem.value}
                                          value={elem.value}
                                        >
                                          {elem.label}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.family_member?.[index]
                                      ?.family_relation && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            errors.family_member?.[index]
                                              ?.family_relation.message,
                                        }}
                                        className="text-danger"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {!slug && (
                        <div className="addBtn-div col-12 col-lg-6">
                          <div
                            className="participants-title d-flex justify-content-between align-items-center"
                            id="participants-title-id"
                          >
                            <h4
                              className="register-title pt-2 label-font col-9"
                              style={{ visibility: "hidden" }}
                            >
                              Participant{" "}
                            </h4>
                          </div>
                          <div
                            className="button-container"
                            id="add_participant"
                            style={{ height: cardDivHeight }}
                            onClick={handleAddParticipant}
                          >
                            <span className="button-text">
                              +<br />
                              Family Member
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* {!slug ? (
                  <>
                    <div className="mb-4">
                      <input
                        type="checkbox"
                        className="mx-2"
                        onClick={handleAgreeCheck}
                        {...register(`terms_condition`, { required: true })}
                      />
                      <span>
                        I agree to{" "}
                        <Link to="/terms-and-condition" target="_blank">
                          Terms and Condition
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    </div>

                    <div className=" d-flex justify-content-end mt-3">
                      <Button
                        className="contact-btn send-btn px-4"
                        type="submit"
                        readOnly={!agree}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className=" d-flex justify-content-end mt-3">
                    <Button className="contact-btn send-btn px-4" type="submit">
                      Submit
                    </Button>
                  </div>
                )} */}
                    <div className="mb-4">
                      <input
                        type="checkbox"
                        className="mx-2"
                        onClick={handleAgreeCheck}
                        {...register(`terms_condition`, { required: true })}
                      />
                      <span>
                        I agree to{" "}
                        <Link to="/terms-and-condition" target="_blank">
                          Terms and Condition
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    </div>

                    <div className=" d-flex justify-content-end mt-3">
                      <Button
                        className="contact-btn send-btn px-4"
                        type="submit"
                        disabled={!agree}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </div>
  );
};

export default Member;
