import { React } from "react";
import { Header, Footer, InternationalEvents } from "../../Common";

const InternationEventsList = (props) => {
  return (
    <>
      <Header links={props.links} />
      <div className=" internationalEvents-section" id="international-events">
        <InternationalEvents />
        <div className="pb-2 pb-lg-5"></div>
      </div>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};

export default InternationEventsList;
