import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  HomePage,
  StaticPage,
  ContactUs,
  Sponsor,
  Member,
  RegisterPreviewPage,
  RegisterConfirmationPage,
  TravelAssistance,
  Donate,
  InternationEventDetailPage,
  InternationEventsList,
  MembershipPreviewPage,
  MembershipConfirmationPage,
  DonationPreviewPage,
  DonationConfirmationPage,
  TravelAssistancePreviewPage,
  TravelConfirmationPage,
} from "../Pages";
import { Register } from "../Pages/Register/Register";
import { Api } from "../Constants";
import { InternationalEvents } from "../Common";
import { Login } from "../Pages/Login";
import { ForgetPassword } from "../Pages/ForgetPassword";
import { SponsorPreviewPage } from "../Pages/SponsorPreviewPage";
import { SponsorConfirmationPage } from "../Pages/SponsorConfirmationPage";
import { Profile } from "../Pages/Profile";
import { News, NewsDetails } from "../Pages/LatestNews";
import Projects from "../Pages/DharmaProjects/Projects";
import Schedule from "../Pages/Schedule/Schedule";

const defaultLinks = {
  facebook: "https://www.facebook.com/televisionsdharma",
  linkedin: "https://www.linkedin.com/in/dharma-television-7642b4237/",
  youtube: "https://www.youtube.com/@dharmatelevision7477",
  mail: "info@dharmatelevision.tv",
  // twitter: 'https://twitter.com/dharmatelevision'
};

const officialContacts = {
  headOffice: "",
  email: "",
  tel: "",
  mobile_no: "",
  branchOffice: "",
  branch_mobile_no: "",
};

const AppRouter = () => {
  const [links, setLinks] = useState(defaultLinks);
  const [contacts, setContacts] = useState(officialContacts);
  const [footerDescription, setFooterDescription] = useState("");

  const handleLinks = async () => {
    const data = await Api.getSiteSetting();
    if (!data) return;
    const settings = data.site_setting.pop();
    if (!settings) return;
    const {
      youtube,
      facebook,
      mail,
      linkedin,
      head_office,
      email,
      tel,
      phone_no,
      branch_office,
      branch_office_no,
    } = settings;
    setLinks({ ...defaultLinks, youtube, facebook, mail, linkedin });
    setContacts({
      ...officialContacts,
      headOffice: head_office,
      email,
      tel,
      mobile_no: phone_no,
      branchOffice: branch_office,
      branch_mobile_no: branch_office_no,
    });
    setFooterDescription(settings.footer_about);
  };

  useEffect(() => {
    handleLinks();
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter basename="">
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/register"
          element={
            <Register
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        {/* <Route path="/preview-page/" element={<RegisterPreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
        {/* <Route path="/register-confirmation/" element={<RegisterConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
        <Route
          path="/edit-registration/"
          element={
            <Register
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/preview-page/:id"
          element={
            <RegisterPreviewPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/register-confirmation/:id"
          element={
            <RegisterConfirmationPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/edit-registration/:id"
          element={
            <Register
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/contact-us"
          element={
            <ContactUs
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/member"
          element={
            <Member
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/preview-membership-page/:id"
          element={
            <MembershipPreviewPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/membership-confirmation/:id"
          element={
            <MembershipConfirmationPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/edit-membership/:id"
          element={
            <Member
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/sponsor"
          element={
            <Sponsor
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/edit-sponsor/:id"
          element={
            <Sponsor
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/preview-sponsor-page/:id"
          element={
            <SponsorPreviewPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/sponsor-confirmation/:id"
          element={
            <SponsorConfirmationPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/donate-now/"
          element={
            <Donate
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/preview-donation/"
          element={
            <DonationPreviewPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/edit-donation/"
          element={
            <Donate
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/donation-confirmation/"
          element={
            <DonationConfirmationPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/travel-assistance/"
          element={
            <TravelAssistance
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/preview-travel-details/"
          element={
            <TravelAssistancePreviewPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/edit-travel-details/:id"
          element={
            <TravelAssistance
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/travel-assistance-confirmation/:id"
          element={
            <TravelConfirmationPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />

        <Route
          path="/international-events/"
          element={
            <InternationEventsList
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        {/* <Route path="/international-events/" element={<InternationalEvents links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}

        <Route
          path="/:slug"
          element={
            <StaticPage
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Login
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/forget_password"
          element={
            <ForgetPassword
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/profile"
          element={
            <Profile
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/latest-news"
          element={
            <News
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/latest-news/:slug"
          element={
            <NewsDetails
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/dharma-projects"
          element={
            <Projects
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route
          path="/schedule"
          element={
            <Schedule
              links={links}
              contacts={contacts}
              footerDescription={footerDescription}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
