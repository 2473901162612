import { Header, Footer } from "../../Common";
import { Link } from "react-router-dom";
const SponsorConfirmationPage = (props) => {
  return (
    <>
      <Header links={props.links} />
      <div className="container text-center">
        <div className="text-congrats mt-5 pt-md-5 mx-md-4">
          Congratulations ! You have Successfully Submitted Your Application for{" "}
          <br />
          'International event Sponsor Member' <br />
          <div className="text-email mb-md-5 pb-md-5 pb-lg-0 mb-lg-2 mt-2 ">
            For further details please contact us at{" "}
            <a
              href={`mailto:${props.contacts.email}`}
              title={props.contacts.email}
              target="_blank"
            >
              {props.contacts.email}
            </a>
          </div>
          <div className="mb-5 pb-md-5 mt-5 row justify-content-center">
            <Link to="/">
              <button className="btn  btn-lg  btn-back">Back to home</button>
            </Link>
          </div>
        </div>
      </div>

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};
export default SponsorConfirmationPage;
