import React, { useState, useEffect } from "react";
import {
  Header,
  Footer,
  Banner,
  InternationalEvents,
  Fancybox,
} from "../../Common";
import { Api, Images, HelperFunction } from "../../Constants";
import { Container, Form, Tab, Tabs, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CgFormatSlash } from "react-icons/cg";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";

import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./Homepage.css";
import "./Schedule.css";
import { Loader } from "../../Common/Loader";
const scheduelData = [
  {
    id: 1,
    month: "January",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "January ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 2,
    month: "February",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "February ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "February ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 3,
    month: "March",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "March ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "March ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 4,
    month: "April",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "April ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 5,
    month: "May",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "May ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 6,
    month: "June",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "June ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 3,
      //   date: "03",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 4,
      //   date: "04",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
    ],
  },
  {
    id: 7,
    month: "July",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "July ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 8,
    month: "August",
    date: [
      // {
      //   id: 1,
      //   date: "01",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "August ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //   id: 2,
      //   date: "02",
      //   title: "Lorem ipsum dolor",
      //   content:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      // },
      // {
      //     id: 3,
      //     date: '03',
      //     title: 'Lorem ipsum dolor',
      //     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.'
      // },
      // {
      //     id: 4,
      //     date: '04',
      //     title: 'Lorem ipsum dolor',
      //     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.'
      // },
    ],
  },
];

const month = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const Schedule = (props) => {
  useEffect(() => {
    getSchedule();
  }, []);

  const date = new Date();
  const currentMonth = month[date.getMonth()];
  const [schedule, setSchedule] = useState([]);
  const [activeTab, setActiveTab] = useState(currentMonth);
  const [loading, setLoading] = useState(true);
  const getSchedule = async () => {
    const res = await Api.getScheduleData();
    // console.log("res", res);
    setSchedule(res.data);
    setLoading(false);
  };
  return (
    <>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="schedule-section" id="schedule">
            <div className="container">
              <div className="mx-2 mx-lg-2 py-3 ">
                <div className="schedule-header">
                  <div className="mt-5 d-flex flex-row align-items-end">
                    <CgFormatSlash
                      color="#fff"
                      className="slash"
                      style={{ marginLeft: "-8px" }}
                    />
                    <CgFormatSlash
                      color="#fff"
                      className="slash"
                      style={{ marginLeft: "-26px" }}
                    />
                    <CgFormatSlash
                      color="#fff"
                      className="slash"
                      style={{ marginLeft: "-26px" }}
                    />
                    <span className="mt-1">Schedule</span>
                  </div>
                  <h1 className="schedule-title text-capitalize my-4">
                    Schedule
                  </h1>
                </div>
                <div>
                  <Tabs
                    defaultActiveKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3 justify-content-start"
                  >
                    {schedule?.map((item, index) => (
                      <Tab
                        eventKey={item.month_name.toLowerCase()}
                        title={item.month_name}
                        key={index}
                      >
                        <div className="schedule-content my-3">
                          {item.schedule_day.length > 0 ? (
                            <>
                              {item.schedule_day?.map((day, index) => (
                                <>
                                  <div
                                    className="schedule-date row "
                                    key={index}
                                  >
                                    <div className="col-3 col-md-2 col-xl-1 text-center date ">
                                      <h2 className="mt-3 mt-lg-4">
                                        {day.name}
                                      </h2>
                                    </div>
                                    <div className="col-9 col-md-9 col-lg-10 ms-xl-4 ps-lg-3 mt-3 mt-lg-4">
                                      <div className="title">{day.title}</div>
                                      <div className="content my-1">
                                        {day.description}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <div className="schedule-date row" key={index}>
                                <h4 className="text-center title py-4">
                                  No Events Available
                                </h4>
                              </div>
                            </>
                          )}
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Schedule Section */}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};
export default Schedule;
