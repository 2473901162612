import React, { useState, useEffect } from "react";
import { Header, Footer } from "../../Common";
import { Api, Images, HelperFunction } from "../../Constants";
import { Container, Form, Tab, Tabs, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CgFormatSlash } from "react-icons/cg";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";

import "./Projects.css";
import { Loader } from "../../Common/Loader";

const Projects = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Header links={props.links} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bg-gray">
            <div className=" projects-section" id="dharma-projects">
              <div className="container">
                <div className="mx-2 mx-lg-2 my-xl-4 pb-3">
                  <div className="projetcs-header text-center">
                    <div className="mt-5 d-flex flex-row align-items-end">
                      <CgFormatSlash
                        color="#db4c54"
                        className="slash"
                        style={{ marginLeft: "-8px" }}
                      />
                      <CgFormatSlash
                        color="#db4c54"
                        className="slash"
                        style={{ marginLeft: "-26px" }}
                      />
                      <CgFormatSlash
                        color="#db4c54"
                        className="slash"
                        style={{ marginLeft: "-26px" }}
                      />
                      <span className="mt-1">Projects</span>
                    </div>
                    <h1 className="projects-title text-capitalize text-start my-4">
                      Dharma Projects
                    </h1>
                  </div>

                  <div className="projects-content  row mb-5">
                    <div className="col-12 col-lg-4">
                      <img
                        src={Images.dharmalogo1}
                        alt="Dharma Idol Logo"
                        className="img-fluid mt-lg-4 w-100 project-img"
                      />
                    </div>
                    <div className="col mt-3 mt-lg-4 mx-3 mx-lg-0 ms-lg-5 pr-3 pb-3">
                      <table className="">
                        <tbody>
                          <tr>
                            <td>
                              <div className="ps-4 pe-3">
                                <img
                                  src={Images.tv}
                                  alt="Dharma Idol Logo"
                                  className="img-fluid tvlogo"
                                />
                                <div className="projects-content-title my-2">
                                  Sponsor Dharma TV programs
                                </div>
                                <div className="projects-content-description">
                                  Dharma Television is established with the pure
                                  objective of benefiting all sentient
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="ps-4 pe-3">
                                <img
                                  src={Images.tv}
                                  alt="Dharma Idol Logo"
                                  className="img-fluid tvlogo"
                                />
                                <div className="projects-content-title my-2">
                                  Sponsor Dharma TV programs
                                </div>
                                <div className="projects-content-description">
                                  Dharma Television is established with the pure
                                  objective of benefiting all sentient
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ps-4 pe-3">
                                <img
                                  src={Images.tv}
                                  alt="Dharma Idol Logo"
                                  className="img-fluid tvlogo"
                                />
                                <div className="projects-content-title my-2">
                                  Sponsor Dharma TV programs
                                </div>
                                <div className="projects-content-description">
                                  Dharma Television is established with the pure
                                  objective of benefiting all sentient
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="ps-4 pe-3">
                                <img
                                  src={Images.tv}
                                  alt="Dharma Idol Logo"
                                  className="img-fluid tvlogo"
                                />
                                <div className="projects-content-title my-2">
                                  Sponsor Dharma TV programs
                                </div>
                                <div className="projects-content-description">
                                  Dharma Television is established with the pure
                                  objective of benefiting all sentient
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="icon4">
          <img
            src={Images.icon4}
            alt="Dharma Idol Logo"
            className="img-fluid"
          />
        </div> */}
            </div>
          </div>
        </>
      )}

      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};
export default Projects;
