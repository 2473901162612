import axios from "axios";
import { toast } from "react-toastify";
import HelperFunction from "./HelperFunction";

axios.defaults.baseURL = HelperFunction.BASE_URL;
const getEventBaseUrl = HelperFunction.GET_EVENT_DETAIL_URL;

const regex = /(<([^>]+)>)/gi;

const getHeaders = async () => {
  const token = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  // new Api().tokenVerification(headers);

  return headers;
};

const errorToast = (error) => {
  toast.error(error.response.data?.message?.replace(regex, ""), {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

const successToast = (message) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

const newerrorToast = (msg) => {
  toast.error(msg, {
    autoClose: 3000,
  });
};

class Api {
  getStaticPage = async (slug) => {
    try {
      const data = await axios.get(`/page/${slug || ""}`);
      const res = data.data;

      // console.log("getStaticPage Res:", res);
      return res;
    } catch (e) {
      // console.log("getStaticPage Error", e.response.data);
      return false;
    }
  };

  getBannerPictures = async (headers) => {
    try {
      const data = await axios.get("/image");
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  getSiteSetting = async () => {
    try {
      const res = await axios.get("/site_setting");
      return res.data;
    } catch (e) {
      // console.log("Fetch products", e.response);
    }
  };

  postContactUs = async (details) => {
    try {
      const res = await axios.post(`/contact/store`, details);
      successToast(res.data.success);
      return res?.data;
    } catch (e) {
      e?.response?.message
        ? errorToast(e.response.message)
        : errorToast("Something went wrong. Please Try Again!");
      // console.log("Contact us", e.response);
    }
  };

  getDonationList = async () => {
    try {
      const res = await axios.get("/donation");
      return res.data;
    } catch (e) {
      // console.log("Fetch products", e.response);
      return e.response.data;
    }
  };

  getInternationalEventsData = async () => {
    try {
      const res = await axios.get("/international_events");
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  };

  getEventDetails = async (slug) => {
    try {
      const data = await axios.get(`/get_event_by_slug/${slug || ""}`);
      const res = data.data;

      // console.log("getStaticPage Res:", res);
      return res;
    } catch (e) {
      // console.log("getStaticPage Error", e.response.data);
      return false;
    }
  };

  getMembershipType = async () => {
    try {
      const res = await axios.get("/get_membership_duration");
      return res.data;
    } catch (e) {
      // console.log("Fetch products", e.response);
      return e.response.data;
    }
  };

  checkEmail = async (email) => {
    try {
      const data = await axios.get(`/check_email?email=${email}`);
      const res = data.data;

      return res;
    } catch (e) {
      console.log("e", e);
      return e.response.data;
    }
  };

  getProgrammes = async () => {
    try {
      const data = await axios.get("/get_event_detail", {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  getCountries = async () => {
    try {
      const data = await axios.get("/get_country_detail", {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  getStateAccToCountry = async (id) => {
    try {
      const data = await axios.get(`/get_state_detail_by_country_id/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  getCityAccToState = async (id) => {
    try {
      const data = await axios.get(`/get_city_detail_by_state_id/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  postRegister = async (postData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };
      const res = await axios.post("/event_registration", postData, {
        headers: headers,
      });
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  getParticiapantDataFromRegistrationId = async (id) => {
    try {
      const data = await axios.get(
        `/participant_detail_by_application_id/${id}`,
        { baseURL: getEventBaseUrl }
      );
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  updateRegister = async (postData, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(
        `/update_event_registration/${id}`,
        postData,
        {
          headers: headers,
        }
      );
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  sendEmail = async (id) => {
    try {
      const data = await axios.get(`/email_part/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  sendEmailtoActivateEventRegistrants = async (id) => {
    try {
      const data = await axios.get(`/account_activation_email/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  postMember = async (postData) => {
    try {
      const headers = await getHeaders();

      const res = await axios.post("/membership_registration", postData, {
        headers: headers,
      });

      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  getDataFromMembershipId = async (id) => {
    try {
      const data = await axios.get(`/membership_detail/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  updateMember = async (postData, id) => {
    try {
      const headers = await getHeaders();

      const res = await axios.post(
        `/update_membership_registration/${id}`,
        postData,
        {
          headers: headers,
        }
      );

      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  sendEmailtoRegisterMember = async (id) => {
    try {
      const data = await axios.get(`/send_email_to_register_member/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  sendEmailtoActivateMember = async (id) => {
    try {
      const data = await axios.get(`/send_email_to_activate_user/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  membershipPaymentGateway = async (id) => {
    try {
      const data = await axios.post(`/membership_payment_page/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return e.response.data;
    }
  };

  getPaymentMethodByCountry = async (id) => {
    try {
      const data = await axios.get(`/get_payment_by_country_id/${id}`);
      const res = data.data;

      return res;
    } catch (e) {
      return false;
    }
  };

  postLogin = async (postData) => {
    try {
      const data = await axios.post("/login_user", postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = data.data;

      console.log("performLogin Res:", res);
      return res;
    } catch (e) {
      console.log("performLogin Error", e.response.status);
      // return e.response.status;
      return false;
    }
  };

  // postDonation = async (postData) => {
  //   try {
  //     const headers = {
  //       "Content-Type": "multipart/form-data",
  //       // Authorization: `Bearer ${token}`,
  //     };
  //     const res = await axios.post('/donor_registration', postData, {
  //       headers: headers,
  //     });
  //     successToast(res?.data?.success);
  //     return res?.data;
  //   } catch (error) {
  //     // Throw the error to be caught and handled by the calling code
  //     return error.response.data;
  //   }
  // };
  postDonation = async (postData) => {
    try {
      const headers = await getHeaders();

      const res = await axios.post("/donor_registration", postData, {
        headers: headers,
      });

      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  getDataFromDonationId = async (id) => {
    try {
      const data = await axios.get(`/donor_detail/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  updateDonation = async (postData, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(
        `/update_donor_registration/${id}`,
        postData,
        {
          headers: headers,
        }
      );
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  sendEmailToDonorMember = async (id) => {
    try {
      const data = await axios.get(`/send_email_to_donor_member/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  paymentGateway = async (id) => {
    try {
      const data = await axios.post(`/donation_payment_page/${id}`);
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  postTravelAssistance = async (postData, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(`/assistance_in_travel/${id}`, postData, {
        headers: headers,
        baseURL: getEventBaseUrl,
      });

      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response?.data;
    }
  };

  updateTravelAssistance = async (postData, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(
        `/update_assistance_in_travel/${id}`,
        postData,
        {
          headers: headers,
          baseURL: getEventBaseUrl,
        }
      );
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response.data;
    }
  };

  postSponsorDetail = async (postData) => {
    try {
      const headers = await getHeaders();
      const res = await axios.post("/store_sponsor", postData, {
        headers: headers,
      });
      successToast(res.data.success);
      console.log("response", res?.data);
      return res?.data;
    } catch (error) {
      error?.response?.data?.message
        ? errorToast(error)
        : errorToast("Sommething went wrong. Please Try Again!");
      // console.log(e?.response?.data?.message);
    }
  };
  updateSponsorDetail = async (postData, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(`/update_sponsor/${id}`, postData, {
        headers: headers,
      });
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      // return error.response.data;
      error?.response?.message
        ? errorToast(error.response.message)
        : errorToast("Sommething went wrong. Please Try Again!");
    }
  };

  getAllSponsorshipType = async () => {
    try {
      const res = await axios.get("/getall_sponsership_type");
      return res.data;
    } catch (e) {
      // console.log("Fetch products", e.response);
      return e.response.data;
    }
  };

  getSponsorDetailById = async (id) => {
    try {
      const data = await axios.get(`/sponsor_detail/${id}`);
      const res = data.data;
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      console.log(e.response.data);
      return false;
    }
  };

  postPayment = async (postData, id) => {
    try {
      const headers = await getHeaders();

      const res = await axios.post(`/sponsor_payment_page/${id}`, postData, {
        headers: headers,
        // baseURL: getEventBaseUrl,
      });
      successToast(res?.data?.success);
      return res?.data;
    } catch (error) {
      // Throw the error to be caught and handled by the calling code
      return error.response?.data;
    }
  };

  sendEmailToSponsorMember = async (id) => {
    try {
      const data = await axios.get(`/send_email_to_sponsor_member/${id}`, {
        // baseURL: getEventBaseUrl,
      });
      const res = data.data;
      return res;
    } catch (e) {
      return false;
    }
  };
  getTravelAssistance = async (id) => {
    try {
      const data = await axios.get(`/assistance_to_travel_detail/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  sendEmailToTravelAssistance = async (id) => {
    try {
      const data = await axios.get(`/sent_email_to_assistance_detail/${id}`, {
        baseURL: getEventBaseUrl,
      });
      const res = data.data;

      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      // console.log("getBannerPictures Error", e.response.data);
      return false;
    }
  };

  sponsorshipPaymentGateway = async (id) => {
    try {
      const data = await axios.post(`/sponsor_payment_page/${id}`);
      const res = data.data;
      // console.log("getBannerPictures Res:", res);
      return res;
    } catch (e) {
      console.log("Error", e.response.data);
      return e.response.data;
    }
  };

  postForgetPassword = async (postData) => {
    try {
      const data = await axios.post(
        "/verify_email_and_reset_password",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = data.data;

      console.log("ForgetPassword Res:", res);
      return res;
    } catch (e) {
      console.log("ForgetPassword Error", e.response.status);
      // return e.response.status;
      return false;
    }
  };

  getNewsData = async () => {
    try {
      const res = await axios.get("/news");
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  };

  getNewsDetailsById = async (slug) => {
    try {
      const data = await axios.get(`/getnews/${slug || ""}`);
      const res = data.data;

      // console.log("getStaticPage Res:", res);
      return res;
    } catch (e) {
      // console.log("getStaticPage Error", e.response.data);
      return false;
    }
  };

  getScheduleData = async () => {
    try {
      const res = await axios.get("/schedule");
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  };
}

export default new Api();
